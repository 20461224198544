<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Новости</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row v-if="!news.length">
                            <v-col md="12" xs="12" lg="12" class="text-center">
                                Новостей нет
                            </v-col>
                        </v-row>
                        <v-row
                            v-for="n in news"
                            :key="n.id"
                        >
                            <v-col>
                                <h3>
                                    <a
                                        :href="'/console/news/' + n.id"
                                        class="text-decoration-none"
                                        :class="n.viewed ? 'grey--text' : ''"
                                    >
                                        {{ n.title }}
                                    </a>
                                    <v-icon v-if="!n.viewed" small>mdi-email-outline</v-icon>
                                </h3>
                                от {{ $moment(n.created * 1000).format('DD.MM.YYYY') }}
                                <p>
                                    {{ n.preview }} ... <a :href="'/console/news/' + n.id">Читать далее</a>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "NewsController",
    data () {
        return {
            news: [],
        }
    },
    created () {
        this.searchNews()
    },
    methods: {
        searchNews () {
            this.$axios.post('/console/news/search')
                .then(res => {
                    if (res.data.success) {
                        this.news = res.data.data
                    }
                })
        },
    },
}
</script>
