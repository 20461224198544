<template>
    <v-container>
        <v-row class="px-3 my-5">
            <div class="text-h6 py-1">
                {{ folder.search.data.name }}
            </div>
        </v-row>

        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Поиск</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form
                            @submit.prevent="search.data.page = 1; searchResume()"
                            autocomplete="off"
                        >
                            <v-text-field
                                v-model="search.data.query"
                                :error-messages="search.errors.query"
                                cache-items
                                label="Поиск по резюме"
                                hide-no-data
                                hide-details
                                class="mb-0"
                                clearable
                            />
                            <v-card-actions class="mt-3">
                                <v-spacer/>
                                <v-btn
                                    :disabled="search.progress"
                                    type="submit"
                                    color="primary"
                                >
                                    Найти
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row
            v-for="r in resume.search.data"
            :key="r.id"
        >
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>
                            <a
                                :href="'/console/resume/' + r.data.id"
                                class="text-decoration-none"
                            >
                                {{ r.data.title }}
                            </a>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <img v-if="r.data.photo" :src="r.data.photo.small" align="right" width="60">
                        <p v-if="r.data.age">
                            Возраст, лет: {{ r.data.age }}
                        </p>
                        <p v-if="r.data.salary">
                            Оплата: {{ r.data.salary.amount }}
                            <span v-if="r.data.salary.currency === 'RUR'">
                                руб.
                            </span>
                            <span v-if="r.data.salary.currency === 'EUR'">
                                EUR
                            </span>
                            <span v-if="r.data.salary.currency === 'USD'">
                                USD
                            </span>
                        </p>
                        <p v-if="r.data.total_experience">
                            Опыт работы:
                            <span v-if="r.data.total_experience.months >= 12">
                                {{ (r.data.total_experience.months / 12).toFixed(0) }} лет
                            </span>
                            <span v-if="r.data.total_experience.months % 12 > 0">
                                {{ r.data.total_experience.months % 12}} месяцев
                            </span>
                        </p>
                        <p v-if="r.data.experience && r.data.experience.length > 0">
                            Последнее место работы: {{ r.data.experience[0].position }}
                            <br>
                            {{ r.data.experience[0].company }}, {{ $moment(r.data.experience[0].start).format('DD.MM.YYYY') }} - <span v-if="r.data.experience[0].end">{{ $moment(r.data.experience[0].end).format('DD.MM.YYYY') }}</span><span v-else>по настоящее время</span>
                        </p>
                        <p class="grey--text">
                            <span v-if="r.data.updated_at">
                                Обновлено
                                <span v-if="$moment(r.data.updated_at).add(1, 'years').isBefore($moment())">
                                    {{ $moment(r.data.updated_at).format('D MMMM YYYY HH:mm') }}
                                </span>
                                <span v-else>
                                    {{ $moment(r.data.updated_at).format('D MMMM HH:mm') }}
                                </span>
                            </span>
                            <span v-else>
                                Создано {{ $moment(r.data.created_at).format('D MMMM HH:mm') }}
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "UserResumeFolderController",
    data () {
        return {
            folder: {
                search: {
                    data: {},
                },
            },
            resume: {
                search: {
                    data: [],
                    progress: false,
                    show: false,
                    wait: false,
                },
            },
            search: {
                data: {
                    query: '',
                    page: 1,
                },
                errors: {
                    query: [],
                },
                progress: false,
            },
        }
    },
    watch: {
        '$route.params.id' () {
            this.searchFolder()
            this.searchResume()
        },
    },
    created () {
        this.searchFolder()
        this.searchResume()
    },
    methods: {
        searchFolder () {
            this.$axios.post('/console/search/folder', {folder_id: this.$route.params.id})
                .then(res => {
                    if (res.data.success) {
                        this.folder.search.data = res.data.data
                    }
                })
        },

        searchResume () {
            this.search.progress = true
            this.$axios.post('/console/user/' + this.$route.params.user_id + '/resume/folder/' + this.$route.params.id, this.search.data)
                .then(res => {
                    if (res.data.success) {
                        this.resume.search.data = res.data.data
                    }
                    this.search.progress = false
                })
        },
    },
}
</script>
