<template>
    <v-container
        fluid
        fill-height
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col xs="12" sm="6" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Вход в личный кабинет</v-toolbar-title>
                    </v-toolbar>
                    <v-form @submit.prevent="signIn()">
                        <v-card-text>
                            <v-text-field
                                v-model="data.email"
                                :error-messages="errors.email"
                                prepend-icon="mdi-email"
                                name="email"
                                label="Почта"
                                mask="email"
                                type="text">
                            </v-text-field>
                            <v-text-field
                                v-model="data.password"
                                :error-messages="errors.password"
                                prepend-icon="mdi-lock"
                                name="password"
                                label="Пароль"
                                type="password"
                            ></v-text-field>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="wait"
                                    :class="[{'js-sign-in-wait': wait}]"
                                    type="submit"
                                    color="primary"
                                >Войти</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SignInController',
    data () {
        return {
            data: {
                email: '',
                password: '',
            },
            errors: {
                email: [],
                password: [],
            },
            wait: false,
        }
    },
    methods: {
        signIn: function () {
            this.wait = true

            this.$axios.post('/console/sign-in', this.data)
                .then(res => {
                    if (res.data.success) {
                        this.$axios.defaults.headers.common['Authorization'] = res.data.data
                        this.$store.commit('updateToken', res.data.data)
                        this.$router.push('/')
                    } else {
                        this.errors.email = res.data.errors.email || []
                        this.errors.password = res.data.errors.password || []
                    }

                    this.wait = false
                })
                .catch(() => {
                    this.wait = false
                })
        },
    },
}
</script>

<style scoped>
</style>
