<template>
    <v-container>
        <v-card v-if="forbidden" width="100%">
            <v-card-text class="error white--text text-center">
                <div>Ваш доступ заблокирован.</div>
                <div>Вы можете работать только с сохраненными резюме</div>
            </v-card-text>
        </v-card>
        <div v-if="!forbidden && resume.data">
            <v-row text-center wrap>
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                {{ resume.name }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <div class="mr-1">
                                <v-btn
                                    @click="showBookmarkPopup"
                                    small icon
                                >
                                    <v-icon>mdi-star-outline</v-icon>
                                </v-btn>
                                <a
                                    v-if="resume.download_free"
                                    :href="exportPDF"
                                    class="text-decoration-none"
                                    small icon
                                >
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </a>
                                <a
                                    v-else
                                    @click="contact.show = true"
                                    class="text-decoration-none"
                                    small icon
                                >
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                </a>
                                <a
                                    v-if="resume.download_free"
                                    :href="exportRTF"
                                    class="text-decoration-none"
                                    small icon
                                >
                                    <v-icon>mdi-file-word-box-outline</v-icon>
                                </a>
                                <a
                                    v-else
                                    @click="contact.show = true"
                                    class="text-decoration-none"
                                    small icon
                                >
                                    <v-icon>mdi-file-word-box-outline</v-icon>
                                </a>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-chip
                                v-if="!!resume.data.job_search_status"
                                class="mb-2"
                            >
                                {{ resume.data.job_search_status.name }}
                            </v-chip>
                            <img
                                v-if="resume.data.photo"
                                @click="lightbox = !lightbox"
                                :src="resume.data.photo.medium"
                                class="photo"
                                align="right"
                                width="60"
                            >
                            <div v-if="resume.data.gender" class="my-1">
                                Пол: {{ resume.data.gender.name }}
                            </div>
                            <div v-if="resume.data.age" class="my-1">
                                Возраст, лет: {{ resume.data.age }}
                            </div>
                            <div v-if="resume.data.salary" class="my-1">
                                Оплата: {{ resume.data.salary.amount }}
                                <span v-if="resume.data.salary.currency === 'RUR'">
                                    руб.
                                </span>
                                <span v-if="resume.data.salary.currency === 'EUR'">
                                    EUR
                                </span>
                                <span v-if="resume.data.salary.currency === 'USD'">
                                    USD
                                </span>
                            </div>
                            <div v-if="resume.data.area" class="my-1">
                                Место проживания: {{ resume.data.area.name }}<span v-if="resume.data.metro">, м. {{ resume.data.metro.name }}</span><span v-if="resume.data.relocation">, {{ resume.data.relocation.type.name }}</span><span v-if="resume.data.business_trip_readiness">, {{ resume.data.business_trip_readiness.name }}</span>
                            </div>
                            <div class="my-1">
                                Занятость: <span class="text-lowercase">{{ resume.data.employments.map(e => {return e.name}).join(', ') }}</span>
                            </div>
                            <div class="my-1">
                                График работы: <span class="text-lowercase">{{ resume.data.schedules.map(s => {return s.name}).join(', ') }}</span>
                            </div>
                            <hr class="grey--text mt-5 mb-4">
                            <div class="mb-5">
                                <span v-for="(item, i) in (new Set(resume.data.specialization.map(s => {return s.profarea_name}))).values()"
                                    :key="i"
                                >
                                    {{ item }}
                                    <ul>
                                        <li v-for="s in resume.data.specialization.filter(s => {return s.profarea_name === item})" :key="s.id">
                                            {{ s.name }}
                                        </li>
                                    </ul>
                                </span>
                            </div>
                            <div class="d-flex mt-3">
                                <v-btn
                                    v-if="!resume.contact && user.accessSchedule === 'allow'"
                                    @click="showContacts"
                                >
                                    Показать контакты
                                </v-btn>
                                <v-spacer></v-spacer>
                                <p class="grey--text mt-3 pt-0 mb-0 pb-0">
                                    <span v-if="resume.data.updated_at">
                                        Обновлено {{ $moment(resume.data.updated_at).format('D MMMM HH:mm') }}
                                    </span>
                                        <span v-else>
                                        Создано {{ $moment(resume.data.created_at).format('D MMMM HH:mm') }}
                                    </span>
                                </p>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Комментарии
                            </v-toolbar-title>
                            <v-spacer />
                            <v-btn
                                @click="comment.create.show = true"
                                color="success"
                            >
                                Добавить
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-data-table
                                :headers="comment.search.headers"
                                :items="comment.search.data"
                                :loading="comment.search.loading"
                            >
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td class="text-left">{{ item.created }}</td>
                                        <td class="text-left">{{ item.comment }}</td>
                                        <td class="layout">
                                            <v-icon
                                                @click.stop="commentRemove(item)"
                                                small
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:no-data>
                                    <div v-if="comment.search.loading">
                                        Идет загрузка
                                    </div>
                                    <div v-if="!comment.search.loading">
                                        Комментарии отсутствуют
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.contact">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Контакты
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="whatsapp && whatsapp.country"
                                :href="'https://wa.me/' + whatsapp.country + whatsapp.city + whatsapp.number"
                                target="_blank"
                                class="text-decoration-none"
                            >
                                <whats-app-icon size="16" class="grey--text mr-2"></whats-app-icon>
                                WhatsApp
                            </v-btn>
                            <v-btn
                                v-if="whatsapp && whatsapp.country"
                                :href="'viber://chat?number=' + whatsapp.country + whatsapp.city + whatsapp.number"
                                target="_blank"
                                class="text-decoration-none ml-5"
                            >
                                <viber-icon size="16" class="grey--text mr-2"></viber-icon>
                                Viber
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <div
                                v-if="resume.data.last_name && resume.data.last_name.length > 0"
                                class="font-weight-bold"
                            >
                                {{ resume.data.last_name }}
                                <span v-if="resume.data.first_name && resume.data.first_name.length > 0">
                                    {{ resume.data.first_name}}
                                </span>
                                <span v-if="resume.data.middle_name && resume.data.middle_name.length > 0">
                                    {{ resume.data.middle_name}}
                                </span>
                            </div>
                            <div v-for="(c, i) in resume.data.contact" :key="i">
                                <span v-if="c.type.id === 'home' && c.value !== null">Телефон: {{ c.value.formatted }}</span>
                                <span v-if="c.type.id === 'cell' && c.value !== null">Мобильный телефон: {{ c.value.formatted }}</span>
                                <div v-if="c.type.id === 'email' && c.value !== null" class="d-inline">
                                    Почта:
                                    <a :href="'mailto:' + c.value">
                                        {{ c.value }}
                                    </a>
                                </div>
                                <strong v-if="c.preferred"> - предпочтительный способ связи</strong>
                            </div>
                            <div v-for="(s) in resume.data.site" :key="s.url">
                                <a :href="s.url" v-if="s.type.id == 'personal'">{{ s.url }}</a>
                                <div v-else>{{ s.type.name }}: {{ s.url }}</div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.total_experience">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Опыт работы
                                <span v-if="resume.data.total_experience.months >= 12">
                                    {{ $tc('yearsDiff', Math.floor(resume.data.total_experience.months / 12)) }}
                                </span>
                                <span v-if="resume.data.total_experience.months % 12 > 0">
                                    {{ $tc('monthsDiff', Math.floor(resume.data.total_experience.months % 12)) }}
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row
                                v-for="(e, i) in resume.data.experience"
                                :key="i"
                            >
                                <v-col xs="4" md="3">
                                    {{ $moment(e.start).format('DD.MM.YYYY') }} - <span v-if="e.end">{{ $moment(e.end).format('DD.MM.YYYY') }}</span><span v-else>по настоящее время</span>
                                    <br>
                                    <span v-if="$moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months') === 0">
                                        {{ $tc('daysDiff', $moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'days')) }}
                                    </span>
                                    <span v-if="$moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months') < 12">
                                        {{ $tc('monthsDiff', Math.floor($moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months'))) }}
                                    </span>
                                    <span v-else-if="$moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months') === 12">
                                        1 год
                                    </span>
                                    <span v-else-if="$moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months') > 12">
                                        {{ $tc('yearsDiff', Math.floor($moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months') / 12)) }} {{ $tc('monthsDiff', Math.floor($moment(e.end || $moment().format('YYYY-MM-DD')).diff($moment(e.start), 'months') % 12)) }}
                                    </span>
                                </v-col>
                                <v-col xs="8" md="9">
                                    <div class="mb-5">
                                        <div>{{ e.company }}</div>
                                        <div v-if="e.industries && e.industries.length > 0">
                                            {{ e.industries.map(i => {return i.name}).join(', ') }}
                                        </div>
                                    </div>
                                    <p v-if="e.company_url"><a :href="e.company_url" target="_blank">{{ e.company_url }}</a></p>
                                    <p v-if="e.area">{{ e.area.name }}</p>
                                    <p><strong>{{ e.position }}</strong></p>
                                    <p class="pre" v-if="e.description">{{ e.description }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.skill_set && resume.data.skill_set.length > 0">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Ключевые навыки
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-chip
                                v-for="(s, i) in resume.data.skill_set"
                                :key="i"
                                class="ma-1"
                            >
                                {{ s }}
                            </v-chip>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.driver_license_types && resume.data.driver_license_types.length > 0">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Опыт вождения
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <div v-if="resume.data.has_vehicle">Имеется собственный автомобиль</div>
                            Права категории {{ resume.data.driver_license_types.map(t => {return t.id}).join(', ') }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.skills">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Обо мне
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p class="pre">{{ resume.data.skills }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.portfolio && resume.data.portfolio.length > 0">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Портфолио
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <a
                                v-for="(item, i) in resume.data.portfolio"
                                @click="lightboxPortfolio.slide = i + 1, lightboxPortfolio.toggler = !lightboxPortfolio.toggler"
                                :key="item.small"
                                class="mr-3"
                            >
                                <img :src="item.small">
                            </a>
                            <FsLightbox
                                :toggler="lightboxPortfolio.toggler"
                                :slide="lightboxPortfolio.slide"
                                :sources="resume.data.portfolio.map(p => {return p.medium})"
                                :title="resume.data.portfolio.map(p => {return p.description})"
                                type="image"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.education">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                <span v-if="resume.data.education.level.id === 'unfinished_higher'">
                                    Неоконченное высшее образование
                                </span>
                                <span v-if="resume.data.education.level.id === 'higher'">
                                    Высшее образование
                                </span>
                                <span v-if="resume.data.education.level.id === 'special_secondary'">
                                    Среднее специальное
                                </span>
                                <span v-if="resume.data.education.level.id === 'secondary'">
                                    Среднее образование
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text
                            v-if="resume.data.education.primary"
                        >
                            <v-row
                                v-for="(e, i) in resume.data.education.primary"
                                :key="i"
                            >
                                <v-col xs="4" md="3">
                                    {{ e.year }}
                                </v-col>
                                <v-col xs="8" md="9">
                                    <p><strong>{{ e.name }}</strong></p>
                                    <p v-if="e.organization">{{ e.organization }}, {{ e.result }}</p>
                                    <p v-else>{{ e.result }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text
                            v-else-if="resume.data.education.elementary"
                        >
                            <v-row
                                v-for="(e, i) in resume.data.education.elementary"
                                :key="i"
                            >
                                <v-col xs="4" md="3">
                                    {{ e.year }}
                                </v-col>
                                <v-col xs="8" md="9">
                                    <p><strong>{{ e.name }}</strong></p>
                                    <p v-if="e.organization">{{ e.organization }}, {{ e.result }}</p>
                                    <p v-else>{{ e.result }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.language && resume.data.language.length > 0">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Знание языков
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row
                                v-for="(l, i) in resume.data.language"
                                :key="i"
                            >
                                <v-col xs="4" md="3">
                                    {{ l.name }}
                                </v-col>
                                <v-col xs="8" md="9">
                                    {{ l.level.name }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row text-center wrap v-if="resume.data.citizenship">
                <v-col xs="12">
                    <v-card>
                        <v-toolbar flat class="grey lighten-4">
                            <v-toolbar-title>
                                Гражданство, время в пути до работы
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col xs="4" md="3">
                                    Гражданство
                                </v-col>
                                <v-col xs="8" md="9">
                                    {{ resume.data.citizenship.map(c => {return c.name}).join(', ') }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col xs="4" md="3">
                                    Разрешение на работу
                                </v-col>
                                <v-col xs="8" md="9">
                                    {{ resume.data.work_ticket.map(c => {return c.name}).join(', ') }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col xs="4" md="3">
                                    Желательное время в пути до работы
                                </v-col>
                                <v-col xs="8" md="9">
                                    {{ resume.data.travel_time.name }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                v-if="bookmark.save.show"
                v-model="bookmark.save.show"
                max-width="500"
            >
                <v-card>
                    <v-card-title class="headline">Избранное</v-card-title>
                    <v-form
                        @submit.prevent="bookmarkResume"
                        autocomplete="off"
                    >
                        <v-card-text>
                            <v-select
                                v-if="bookmark.folders.length > 0"
                                v-model="bookmark.save.data.folder_id"
                                :error-messages="bookmark.save.errors.folder_id"
                                :items="bookmark.folders"
                                :cache-items="true"
                                item-text="name"
                                item-value="id"
                                label="Папка"
                            >
                            </v-select>
                            <v-checkbox
                                v-model="bookmark.save.data.create"
                                label="Новая папка"
                            >
                            </v-checkbox>
                            <v-text-field
                                v-if="bookmark.save.data.create"
                                v-model="bookmark.save.data.name"
                                :error-messages="bookmark.save.errors.name"
                                class="ma-0 pa-0"
                                name="name"
                                label="Название папки"
                                type="text">
                            </v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="bookmark.save.show = false"
                                :disabled="bookmark.save.progress"
                                text
                                color="error"
                            >
                                Отменить
                            </v-btn>
                            <v-btn
                                :loading="bookmark.save.progress"
                                type="submit"
                                color="primary"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <v-dialog
                v-if="comment.create.show"
                v-model="comment.create.show"
                max-width="500"
            >
                <v-card>
                    <v-card-title class="headline">Комментарий</v-card-title>
                    <v-form
                        @submit.prevent="commentCreate"
                        autocomplete="off"
                    >
                        <v-card-text>
                            <v-text-field
                                v-model="comment.create.data.comment"
                                :error-messages="comment.create.errors.comment"
                                class="ma-0 pa-0"
                                name="comment"
                                label="Комментарий"
                                type="text"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                @click="comment.create.show = false"
                                :disabled="comment.create.progress"
                                text
                                color="error"
                            >
                                Отменить
                            </v-btn>
                            <v-btn
                                :loading="comment.create.progress"
                                type="submit"
                                color="primary"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <v-dialog
                v-if="contact.show"
                v-model="contact.show"
                max-width="500"
            >
                <v-card>
                    <v-card-title class="headline">Скачивание файла</v-card-title>
                    <v-form
                        @submit.prevent="showContacts"
                        autocomplete="off"
                    >
                        <v-card-text>
                            При скачивании файла по данному резюме будет засчитан просмотр контактов
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                @click="contact.show = false"
                                :disabled="contact.progress"
                                text
                                color="error"
                            >
                                Отменить
                            </v-btn>
                            <v-btn
                                :loading="contact.progress"
                                type="submit"
                                color="primary"
                            >
                                Показать контакты
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <FsLightbox
                v-if="resume.data.photo"
                :toggler="lightbox"
                :sources="[resume.data.photo.medium]"
                type="image"
            />
        </div>
    </v-container>
</template>

<script>
import FsLightbox from "fslightbox-vue"
import { ViberIcon, WhatsAppIcon } from 'vue-simple-icons'

export default {
    name: "ResumeController",
    components: {FsLightbox, ViberIcon, WhatsAppIcon},
    data () {
        return {
            bookmark: {
                folders: [],
                save: {
                    data: {
                        create: false,
                        folder_id: '',
                        name: '',
                        resume_id: '',
                    },
                    errors: {
                        folder_id: '',
                        name: '',
                    },
                    progress: false,
                    show: false,
                },
            },
            comment: {
                create: {
                    data: {
                        comment: '',
                    },
                    errors: {
                        comment: '',
                    },
                    progress: false,
                    show: false,
                },
                search: {
                    data: [],
                    headers: [
                        {
                            text: 'Дата',
                            value: 'created',
                            sortable: false,
                            width: 150,
                        },
                        {
                            text: 'Комментарий',
                            value: 'comment',
                            sortable: false,
                        },
                        {
                            text: 'Действия',
                            value: 'created',
                            sortable: false,
                            width: 100,
                        },
                    ],
                    loading: false,
                },
            },
            contact: {
                progress: false,
                show: false,
            },
            data: {
                query: '',
                specialization: [],
                world: [],
            },
            errors: {
                query: [],
                world: [],
            },
            forbidden: false,
            lightbox: false,
            lightboxPortfolio: {
                toggler: false,
                slide: 1,
            },
            progress: false,
            resume: {},
            show: false,
            specialization: {
                data: [],
                selected: [],
                show: false,
            },
            temp: {
                world: '',
            },
            wait: false,
            world: {
                data: [],
                selected: [],
                show: false,
            },
        }
    },
    computed: {
        exportPDF () {
            return process.env.VUE_APP_API_ENDPOINT + '/console/resume/' + this.resume.id + '/pdf'
        },
        exportRTF () {
            return process.env.VUE_APP_API_ENDPOINT + '/console/resume/' + this.resume.id + '/rtf'
        },
        specializationChildren (parent_id) {
            return this.specialization.data.filter(s => {return s.id === parent_id})
        },
        specializationParents () {
            return this.specialization.data.filter(s => {return s.parent_id === null})
        },
        specializationChildrenSelected () {
            return this.specialization.data.filter(s => {return s.parent_id !== null && this.data.specialization.includes(s.id)})
        },
        specializationParentsSelected () {
            let ids = []

            this.specialization.data.forEach(s => {
                if (s.parent_id === null) {
                    if (this.data.specialization.includes(s.id)) {
                        ids.push(s.id)
                    }
                } else if (this.data.specialization.includes(s.id) && !ids.includes(s.parent_id)) {
                    ids.push(s.parent_id)
                }
            })

            return this.specialization.data.filter(s => {return ids.includes(s.id)})
        },
        user () {
            return this.$store.getters.user
        },
        whatsapp () {
            const contact = this.resume.data.contact.filter(c => {return c.type.id === 'cell'})
            return contact.length > 0 ? contact[0].value : null
        },
        worldSelected () {
            return this.world.data.filter(w => {return this.data.world.includes(w.id)})
        },
    },
    created () {
        this.searchSpecialization()
        this.searchResume()
        this.searchFolders()
    },
    methods: {
        searchComments () {
            this.$axios.post('/console/resume/' + this.resume.data.id + '/comment')
                .then(res => {
                    if (res.data.success) {
                        this.comment.search.data = res.data.data
                    }
                })
        },

        searchResume () {
            this.$axios.post('/console/resume/' + this.$route.params.id, {}, {
                validateStatus: () => {return true}
            })
                .then(res => {
                    this.forbidden = res.status === 429
                    if (res.data.success) {
                        this.resume = res.data.data
                        this.searchComments()
                    }
                })
        },

        searchFolders () {
            this.$axios.post('/console/search/folder')
                .then(res => {
                    if (res.data.success) {
                        this.bookmark.folders = res.data.data
                    }
                })
        },

        searchSpecialization () {
            this.$axios.post('/console/search/specialization')
                .then(res => {
                    if (res.data.success) {
                        this.specialization.data = res.data.data
                    }
                })
        },

        searchWorld () {
            this.$axios.post('/console/search/world')
                .then(res => {
                    if (res.data.success) {
                        this.world.data = res.data.data
                    }
                })
        },

        addWorld (world) {
            this.data.world.push(world)
            this.$nextTick(() => {
                this.temp.world = ''
            })
        },

        applySpecialization () {
            this.data.specialization = [...this.specialization.selected]
            this.specialization.show = false
        },

        bookmarkResume () {
            this.bookmark.save.progress = true

            this.bookmark.save.errors.folder_id = []
            this.bookmark.save.errors.name = []

            this.$axios.post('/console/resume/bookmark', this.bookmark.save.data)
                .then((res) => {
                    if (res.data.success) {
                        this.bookmark.save.show = false
                        this.bookmark.save.data.create = false
                        this.bookmark.save.data.folder_id = ''
                        this.bookmark.save.data.name = ''
                        this.bookmark.save.data.resume_id = ''

                        this.searchResume()
                        this.searchFolders()
                    } else {
                        this.bookmark.save.errors.folder_id = res.data.errors.folder_id || []
                        this.bookmark.save.errors.name = res.data.errors.name || []
                    }

                    this.bookmark.save.progress = false
                })
                .catch(() => {
                    this.bookmark.save.progress = false
                })
        },

        changeSpecialization (specialization) {
            if (this.specialization.selected.includes(specialization.id)) {
                if (specialization.parent_id === null) {
                    this.specialization.data.forEach(spec => {
                        if (spec.parent_id === specialization.id) {
                            this.specialization.selected.push(spec.id)
                        }
                    })
                } else if (!this.specialization.selected.includes(specialization.parent_id)) {
                    let all = true

                    this.specialization.data.forEach(spec => {
                        if (spec.parent_id === specialization.parent_id && !this.specialization.selected.includes(spec.id)) {
                            all = false
                        }
                    })

                    if (all) {
                        this.specialization.selected.push(specialization.parent_id)
                    }
                }
            } else {
                if (specialization.parent_id === null) {
                    this.specialization.data.forEach(spec => {
                        if (spec.parent_id === specialization.id) {
                            this.specialization.selected = this.specialization.selected.filter(s => s !== spec.id)
                        }
                    })
                } else {
                    this.specialization.selected = this.specialization.selected.filter(s => s !== specialization.parent_id)
                }
            }
        },

        commentCreate () {
            this.comment.create.progress = true

            this.comment.create.errors.comment = []

            this.$axios.post('/console/resume/comment/create', {...this.comment.create.data, resume_id: this.resume.data.id})
                .then((res) => {
                    if (res.data.success) {
                        this.comment.create.show = false
                        this.comment.create.data.comment = ''

                        this.searchComments()
                    } else {
                        this.comment.create.errors.comment = res.data.errors.comment || []
                    }

                    this.comment.create.progress = false
                })
                .catch(() => {
                    this.comment.create.progress = false
                })
        },

        commentRemove (comment) {
            this.$axios.post('/console/resume/comment/remove', {id: comment.id})
                .then(() => {
                    this.searchComments()
                })
                .catch(() => {
                    this.searchComments()
                })
        },

        hideSpecializationPopup () {
            this.specialization.show = false
        },

        removeSpecialization (specialization) {
            this.data.specialization = this.data.specialization.filter(s => s !== specialization.id)
            if (specialization.parent_id === null) {
                this.specialization.data.forEach(spec => {
                    if (spec.parent_id === specialization.id) {
                        this.data.specialization = this.data.specialization.filter(s => s !== spec.id)
                    }
                })
            } else {
                this.data.specialization = this.data.specialization.filter(s => s !== specialization.parent_id)
            }
        },

        removeWorld (world) {
            this.data.world = this.data.world.filter(w => w !== world.id)
        },

        showBookmarkPopup () {
            this.bookmark.save.data.resume_id = this.resume.data.id
            this.bookmark.save.data.folder_id = this.resume.folder_id
            this.bookmark.save.show = true
        },

        showContacts () {
            this.contact.progress = true

            this.$axios.post('/console/resume/contact', {resume_id: this.resume.data.id})
                .then((res) => {
                    if (res.data.success) {
                        this.searchResume()
                    } else {
                        // this.contact.errors.name = res.data.errors.name || []
                    }

                    this.contact.show = false
                    this.contact.progress = false
                })
                .catch(() => {
                    this.contact.progress = false
                })
        },

        showSpecializationPopup () {
            this.specialization.selected = [...this.data.specialization]
            this.specialization.show = true
        },
    },
}
</script>

<style>
.photo {
    cursor: pointer;
}

.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
    color: rgba(0, 0, 0, 0.9);
}

.pre {
    white-space: pre-line;
}
</style>
