<template>
    <v-container>
        <v-row class="px-3 my-5">
            <div class="text-h6 py-1">
                {{ folder.search.data.name }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
                @click="removeFolderPopup"
                small
            >
                Удалить папку
            </v-btn>
        </v-row>

        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Поиск</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form
                            @submit.prevent="search.data.page = 1; searchResume()"
                            autocomplete="off"
                        >
                            <v-text-field
                                v-model="search.data.query"
                                :error-messages="search.errors.query"
                                cache-items
                                label="Поиск по резюме"
                                hide-no-data
                                hide-details
                                class="mb-0"
                                clearable
                            />
                            <v-card-actions class="mt-3">
                                <v-spacer/>
                                <v-btn
                                    :disabled="search.progress"
                                    type="submit"
                                    color="primary"
                                >
                                    Найти
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-row
            v-for="r in resume.search.data"
            :key="r.id"
        >
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>
                            <a
                                :href="'/console/resume/' + r.data.id"
                                class="text-decoration-none"
                            >
                                {{ r.data.title }}
                            </a>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="removeResumePopup(r)"
                            icon fab
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <img v-if="r.data.photo" :src="r.data.photo.small" align="right" width="60">
                        <p v-if="r.data.age">
                            Возраст, лет: {{ r.data.age }}
                        </p>
                        <p v-if="r.data.salary">
                            Оплата: {{ r.data.salary.amount }}
                            <span v-if="r.data.salary.currency === 'RUR'">
                                руб.
                            </span>
                            <span v-if="r.data.salary.currency === 'EUR'">
                                EUR
                            </span>
                            <span v-if="r.data.salary.currency === 'USD'">
                                USD
                            </span>
                        </p>
                        <p v-if="r.data.total_experience">
                            Опыт работы:
                            <span v-if="r.data.total_experience.months >= 12">
                                {{ (r.data.total_experience.months / 12).toFixed(0) }} лет
                            </span>
                            <span v-if="r.data.total_experience.months % 12 > 0">
                                {{ r.data.total_experience.months % 12}} месяцев
                            </span>
                        </p>
                        <p v-if="r.data.experience && r.data.experience.length > 0">
                            Последнее место работы: {{ r.data.experience[0].position }}
                            <br>
                            {{ r.data.experience[0].company }}, {{ $moment(r.data.experience[0].start).format('DD.MM.YYYY') }} - <span v-if="r.data.experience[0].end">{{ $moment(r.data.experience[0].end).format('DD.MM.YYYY') }}</span><span v-else>по настоящее время</span>
                        </p>
                        <p class="grey--text">
                            <span v-if="r.data.updated_at">
                                Обновлено
                                <span v-if="$moment(r.data.updated_at).add(1, 'years').isBefore($moment())">
                                    {{ $moment(r.data.updated_at).format('D MMMM YYYY HH:mm') }}
                                </span>
                                <span v-else>
                                    {{ $moment(r.data.updated_at).format('D MMMM HH:mm') }}
                                </span>
                            </span>
                            <span v-else>
                                Создано {{ $moment(r.data.created_at).format('D MMMM HH:mm') }}
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-if="folder.remove.show"
            v-model="folder.remove.show"
            max-width="600"
        >
            <v-card>
                <v-card-title class="headline">Удалить папку {{ folder.remove.data.name }}?</v-card-title>
                <v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="folder.remove.show = false"
                        :disabled="folder.remove.progress"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removeFolder"
                        :loading="folder.remove.progress"
                        color="green darken-1"
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="resume.remove.show"
            v-model="resume.remove.show"
            max-width="600"
        >
            <v-card>
                <v-card-title class="headline">Удалить резюме?</v-card-title>
                <v-card-text class="mt-5">
                    {{ resume.remove.data.name }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="resume.remove.show = false"
                        :disabled="resume.remove.progress"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removeResume"
                        :loading="resume.remove.progress"
                        color="green darken-1"
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "ResumeFolderController",
    data () {
        return {
            folder: {
                search: {
                    data: {},
                },
                remove: {
                    data: {
                        id: 0,
                        name: '',
                    },
                    progress: false,
                    show: false,
                },
            },
            resume: {
                remove: {
                    data: {
                        id: 0,
                        name: '',
                    },
                    progress: false,
                    show: false,
                },
                search: {
                    data: [],
                    progress: false,
                    show: false,
                    wait: false,
                },
            },
            search: {
                data: {
                    query: '',
                    page: 1,
                },
                errors: {
                    query: [],
                },
                progress: false,
            },
        }
    },
    watch: {
        '$route.params.id' () {
            this.searchFolder()
            this.searchResume()
        },
    },
    created () {
        this.searchFolder()
        this.searchResume()
    },
    methods: {
        removeFolder () {
            this.folder.remove.progress = true

            this.$axios.post('/console/search/folder/remove', this.folder.remove.data)
                .then((res) => {
                    if (res.data.success) {
                        this.folder.remove.show = false
                        this.folder.remove.data.id = 0
                        this.folder.remove.data.name = ''

                        this.$router.push('/search')
                    }

                    this.folder.remove.progress = false
                })
                .catch(() => {
                    this.folder.remove.progress = false
                })
        },

        removeFolderPopup () {
            this.folder.remove.data.id = this.folder.search.data.id
            this.folder.remove.data.name = this.folder.search.data.name
            this.folder.remove.show = true
        },

        removeResume () {
            this.resume.remove.progress = true

            this.$axios.post('/console/resume/remove', this.resume.remove.data)
                .then((res) => {
                    if (res.data.success) {
                        this.resume.remove.show = false
                        this.resume.remove.data.id = 0
                        this.resume.remove.data.name = ''

                        this.searchResume()
                    }

                    this.resume.remove.progress = false
                })
                .catch(() => {
                    this.resume.remove.progress = false
                })
        },

        removeResumePopup (resume) {
            this.resume.remove.data.id = resume.id
            this.resume.remove.data.name = resume.name
            this.resume.remove.show = true
        },

        searchFolder () {
            this.$axios.post('/console/search/folder', {folder_id: this.$route.params.id})
                .then(res => {
                    if (res.data.success) {
                        this.folder.search.data = res.data.data
                    }
                })
        },

        searchResume () {
            this.search.progress = true
            this.$axios.post('/console/resume/folder/' + this.$route.params.id, this.search.data)
                .then(res => {
                    if (res.data.success) {
                        this.resume.search.data = res.data.data
                    }
                    this.search.progress = false
                })
        },
    },
}
</script>
