const state = {
    userAccessSchedule: localStorage.getItem('user-access-schedule') || null,
    userAccessTill: localStorage.getItem('user-access-till') || null,
    userContactBasicLimit: localStorage.getItem('user-contact-basic-limit') || null,
    userContactExtraLimit: localStorage.getItem('user-contact-extra-limit') || null,
    userFolders: localStorage.getItem('user-folders') || null,
    userHistory: localStorage.getItem('user-history') || null,
    userNews: localStorage.getItem('user-news') || null,
    userResumeBasicLimit: localStorage.getItem('user-resume-basic-limit') || null,
    userResumeExtraLimit: localStorage.getItem('user-resume-extra-limit') || null,
    userResumeHideViewed: localStorage.getItem('user-resume-hide-viewed') || false,
}

const getters = {
    user: function (state) {
        return {
            accessSchedule: state.userAccessSchedule,
            accessTill: state.userAccessTill,
            contactBasicLimit: state.userContactBasicLimit,
            contactExtraLimit: state.userContactExtraLimit,
            folders: state.userFolders,
            history: state.userHistory,
            news: state.userNews,
            resumeBasicLimit: state.userResumeBasicLimit,
            resumeExtraLimit: state.userResumeExtraLimit,
            resumeHideViewed: state.userResumeHideViewed,
        }
    },
}

const mutations = {
    removeUser (state) {
        localStorage.removeItem('user-access-schedule')
        localStorage.removeItem('user-access-till')
        localStorage.removeItem('user-contact-basic-limit')
        localStorage.removeItem('user-contact-extra-limit')
        localStorage.removeItem('user-folders')
        localStorage.removeItem('user-history')
        localStorage.removeItem('user-news')
        localStorage.removeItem('user-resume-basic-limit')
        localStorage.removeItem('user-resume-extra-limit')
        state.userAccessSchedule = null
        state.userAccessTill = null
        state.userContactBasicLimit = null
        state.userContactExtraLimit = null
        state.userFolders = null
        state.userHistory = null
        state.userNews = null
        state.userResumeBasicLimit = null
        state.userResumeExtraLimit = null
    },
    updateUserAccessSchedule (state, schedule) {
        localStorage.setItem('user-access-schedule', schedule)
        state.userAccessSchedule = schedule
    },
    updateUserAccessTill (state, date) {
        localStorage.setItem('user-access-till', date)
        state.userAccessTill = date
    },
    updateUserFolders (state, folders) {
        localStorage.setItem('user-folders', folders)
        state.userFolders = folders
    },
    updateUserHistory (state, history) {
        localStorage.setItem('user-history', history)
        state.userHistory = history
    },
    updateUserNews (state, news) {
        localStorage.setItem('user-news', news)
        state.userNews = news
    },
    updateUserContactBasicLimit (state, limit) {
        localStorage.setItem('user-contact-basic-limit', limit)
        state.userContactBasicLimit = limit
    },
    updateUserContactExtraLimit (state, limit) {
        localStorage.setItem('user-contact-extra-limit', limit)
        state.userContactExtraLimit = limit
    },
    updateUserResumeBasicLimit (state, limit) {
        localStorage.setItem('user-resume-basic-limit', limit)
        state.userResumeBasicLimit = limit
    },
    updateUserResumeExtraLimit (state, limit) {
        localStorage.setItem('user-resume-extra-limit', limit)
        state.userResumeExtraLimit = limit
    },
    updateUserResumeHideViewed (state, hide) {
        localStorage.setItem('user-resume-hide-viewed', hide)
        state.userResumeHideViewed = hide
    },
}

export default {
    state,
    getters,
    mutations,
}
