<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>{{ news.title }}</v-toolbar-title>
                        <v-spacer/>
                        {{ $moment(news.created * 1000).format('YYYY-MM-DD HH:mm') }}
                    </v-toolbar>
                    <v-card-text>
                        <div v-html="news.content"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "NewsViewController",
    data () {
        return {
            news: {},
        }
    },
    created () {
        this.viewNews()
    },
    methods: {
        viewNews () {
            this.$axios.post('/console/news/view', {id: this.$route.params.id})
                .then(res => {
                    if (res.data.success) {
                        this.news = res.data.data
                    }
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        this.$router.push('/news')
                    }
                })
        },
    },
}
</script>
