import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import History from '../components/controllers/HistoryController.vue'
import News from '../components/controllers/NewsController.vue'
import NewsView from '../components/controllers/NewsViewController.vue'
import Resume from '../components/controllers/ResumeController.vue'
import ResumeFolder from '../components/controllers/ResumeFolderController.vue'
import Search from '../components/controllers/SearchController.vue'
import SignIn from '../components/controllers/SignInController.vue'
import UserHistory from '../components/controllers/UserHistoryController.vue'
import UserResumeFolder from '../components/controllers/UserResumeFolderController.vue'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/sign-in')
}

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}


export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Search',
            component: Search,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/history',
            name: 'History',
            component: History,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/news',
            name: 'News',
            component: News,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/news/:id',
            name: 'NewsView',
            component: NewsView,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/resume/folder/:id',
            name: 'ResumeFolder',
            component: ResumeFolder,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/resume/:id',
            name: 'Resume',
            component: Resume,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/sign-in',
            name: 'SignIn',
            component: SignIn,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/user/:user_id/history',
            name: 'UserHistory',
            component: UserHistory,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/user/:user_id/resume/folder/:id',
            name: 'UserResumeFolder',
            component: UserResumeFolder,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '*',
            beforeEnter: (to, from, next) => {
                next('/')
            },
        }
    ]
})
