<template>
    <v-container>
        <v-row v-if="$moment().diff($moment(user.accessTill), 'minutes') > 0 || (user.resumeBasicLimit < 1 && user.resumeExtraLimit < 1) || user.accessSchedule !== 'allow'">
            <v-card width="100%">
                <v-card-text class="error white--text text-center">
                    <div v-if="$moment().diff($moment(user.accessTill), 'minutes') > 0 || (user.resumeBasicLimit < 1 && user.resumeExtraLimit < 1)">
                        Ваш доступ заблокирован.
                    </div>
                    <div v-else>
                        Доступ возможен в рабочее время
                    </div>
                    <div>Вы можете работать только с сохраненными резюме</div>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row v-if="warning">
            <v-card width="100%">
                <v-card-text class="error white--text text-center">
                    Использование ссылок в форме поиска запрещено. Повторный ввод приведет к блокировке учетных записей вашей компании.
                </v-card-text>
            </v-card>
        </v-row>
        <v-row v-if="$moment(user.accessTill).diff($moment(), 'minutes') > 0 && (user.resumeBasicLimit > 0 || user.resumeExtraLimit > 0) && user.accessSchedule === 'allow'" text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Поиск</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="hide = !hide"
                        >
                            Расширенный поиск
                        </v-btn>
                    </v-toolbar>
                    <v-card-text
                        v-if="!hide"
                    >
                        <v-form
                            @submit.prevent="data.page = 1; searchResume()"
                            autocomplete="off"
                        >
                            <v-card-text>
                                <v-row>
                                    <v-col xs="10" md="10">
                                        <v-combobox
                                            v-model="data.query1"
                                            :loading="autocomplete1.loading"
                                            :items="autocomplete1.items"
                                            :search-input.sync="autocomplete1.search"
                                            :error-messages="errors.query1"
                                            cache-items
                                            label="Поиск по резюме"
                                            hide-no-data
                                            hide-details
                                            class="mb-0"
                                        />
                                    </v-col>
                                </v-row>
                                <div>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs,  }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey--text custom-transform-class text-none mb-3 ma-0 pa-0 mr-5"
                                                dark
                                                text
                                            >
                                                <v-icon>mdi-menu-down</v-icon>
                                                <span v-if="data.query1_logic === 'all'">Все слова</span>
                                                <span v-if="data.query1_logic === 'any'">Любое из слов</span>
                                                <span v-if="data.query1_logic === 'phrase'">Точная фраза</span>
                                                <span v-if="data.query1_logic === 'except'">Не встречаются</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group
                                                        v-model="data.query1_logic"
                                                    >
                                                        <v-list-item value="all">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Все слова</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="any">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Любое из слов</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="phrase">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Точная фраза</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="except">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Не встречаются</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs,  }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey--text custom-transform-class text-none mb-3 ma-0 pa-0"
                                                dark
                                                text
                                            >
                                                <v-icon>mdi-menu-down</v-icon>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'everywhere'">Везде</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'title'">В названии резюме</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'education'">В образовании</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'skills'">В ключевых навыках</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'experience'">В опыте работы</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'experience_company'">В компаниях и отраслях</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'experience_position'">В должностях</span>
                                                <span v-if="data.query1_fields.length === 1 && data.query1_fields[0] === 'experience_description'">В обязанностях</span>
                                                <span v-if="data.query1_fields.length > 1">В {{ data.query1_fields.length }} разделах резюме</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                <v-treeview
                                                    v-model="data.query1_fields"
                                                    :items="query_fields"
                                                    selectable
                                                    activatable
                                                    open-all
                                                ></v-treeview>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </div>
                                <v-btn
                                    v-if="queries === 1"
                                    @click="queries++"
                                    small
                                    right
                                    text
                                    color="primary"
                                    class="mb-3"
                                >
                                    Добавить ещё поле
                                </v-btn>
                                <v-row v-if="queries > 1">
                                    <v-col xs="10" md="10">
                                        <v-combobox
                                            v-if="queries > 1"
                                            v-model="data.query2"
                                            :loading="autocomplete2.loading"
                                            :items="autocomplete2.items"
                                            :search-input.sync="autocomplete2.search"
                                            :error-messages="errors.query2"
                                            cache-items
                                            label="Поиск по резюме"
                                            hide-no-data
                                            hide-details
                                            class="mb-0"
                                        />
                                    </v-col>
                                    <v-col xs="2" md="2">
                                        <v-btn
                                            v-if="queries === 2"
                                            @click="data.query2 = ''; data.query2_logic = 'all'; data.query2_fields = ['everywhere']; queries--"
                                            x-small
                                            right
                                            fab
                                            icon
                                            class="mt-4"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <div v-if="queries > 1">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs,  }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey--text custom-transform-class text-none mb-3 ma-0 pa-0 mr-5"
                                                dark
                                                text
                                            >
                                                <v-icon>mdi-menu-down</v-icon>
                                                <span v-if="data.query2_logic === 'all'">Все слова</span>
                                                <span v-if="data.query2_logic === 'any'">Любое из слов</span>
                                                <span v-if="data.query2_logic === 'phrase'">Точная фраза</span>
                                                <span v-if="data.query2_logic === 'except'">Не встречаются</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group
                                                        v-model="data.query2_logic"
                                                    >
                                                        <v-list-item value="all">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Все слова</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="any">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Любое из слов</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="phrase">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Точная фраза</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="except">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Не встречаются</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs,  }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey--text custom-transform-class text-none mb-3 ma-0 pa-0"
                                                dark
                                                text
                                            >
                                                <v-icon>mdi-menu-down</v-icon>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'everywhere'">Везде</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'title'">В названии резюме</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'education'">В образовании</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'skills'">В ключевых навыках</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'experience'">В опыте работы</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'experience_company'">В компаниях и отраслях</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'experience_position'">В должностях</span>
                                                <span v-if="data.query2_fields.length === 1 && data.query2_fields[0] === 'experience_description'">В обязанностях</span>
                                                <span v-if="data.query2_fields.length > 1">В {{ data.query2_fields.length }} разделах резюме</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                <v-treeview
                                                    v-model="data.query2_fields"
                                                    :items="query_fields"
                                                    selectable
                                                    activatable
                                                    open-all
                                                ></v-treeview>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </div>
                                <v-btn
                                    v-if="queries === 2"
                                    @click="queries++"
                                    small
                                    right
                                    text
                                    color="primary"
                                    class="mb-3"
                                >
                                    Добавить ещё поле
                                </v-btn>
                                <v-row v-if="queries === 3">
                                    <v-col xs="10" md="10">
                                        <v-combobox
                                            v-model="data.query3"
                                            :loading="autocomplete3.loading"
                                            :items="autocomplete3.items"
                                            :search-input.sync="autocomplete3.search"
                                            :error-messages="errors.query3"
                                            cache-items
                                            label="Поиск по резюме"
                                            hide-no-data
                                            hide-details
                                            class="mb-0"
                                        />
                                    </v-col>
                                    <v-col xs="2" md="2">
                                        <v-btn
                                            v-if="queries === 3"
                                            @click="data.query3 = ''; data.query3_logic = 'all'; data.query3_fields = ['everywhere']; queries--"
                                            x-small
                                            right
                                            fab
                                            icon
                                            class="mt-4"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <div v-if="queries === 3">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs,  }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey--text custom-transform-class text-none mb-3 ma-0 pa-0 mr-5"
                                                dark
                                                text
                                            >
                                                <v-icon>mdi-menu-down</v-icon>
                                                <span v-if="data.query3_logic === 'all'">Все слова</span>
                                                <span v-if="data.query3_logic === 'any'">Любое из слов</span>
                                                <span v-if="data.query3_logic === 'phrase'">Точная фраза</span>
                                                <span v-if="data.query3_logic === 'except'">Не встречаются</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group
                                                        v-model="data.query3_logic"
                                                    >
                                                        <v-list-item value="all">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Все слова</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="any">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Любое из слов</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="phrase">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Точная фраза</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="except">
                                                            <v-list-item-content>
                                                                <v-list-item-title>Не встречаются</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs,  }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                class="grey--text custom-transform-class text-none mb-3 ma-0 pa-0"
                                                dark
                                                text
                                            >
                                                <v-icon>mdi-menu-down</v-icon>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'everywhere'">Везде</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'title'">В названии резюме</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'education'">В образовании</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'skills'">В ключевых навыках</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'experience'">В опыте работы</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'experience_company'">В компаниях и отраслях</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'experience_position'">В должностях</span>
                                                <span v-if="data.query3_fields.length === 1 && data.query3_fields[0] === 'experience_description'">В обязанностях</span>
                                                <span v-if="data.query3_fields.length > 1">В {{ data.query3_fields.length }} разделах резюме</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                <v-treeview
                                                    v-model="data.query3_fields"
                                                    :items="query_fields"
                                                    selectable
                                                    activatable
                                                    open-all
                                                ></v-treeview>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </div>
                                <v-row>
                                    <v-col md="4">
                                        Профессиональная область
                                    </v-col>
                                    <v-col md="8">
                                        <a @click.prevent="showSpecializationPopup">Изменить профобласти</a>
                                        <v-list
                                            class="ml-0 mt-2 pa-0"
                                            dense
                                        >
                                            <v-list-group
                                                v-for="(parent) in specializationParentsSelected"
                                                :key="parent.id"
                                            >
                                                <template v-slot:activator
                                                >
                                                    <v-list-item-action
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-btn
                                                            @click.stop="removeSpecialization(parent)"
                                                            small
                                                            icon
                                                        >
                                                            <v-icon small color="grey lighten-1">mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="pl-2">
                                                            {{ parent.name }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                                <v-list-item
                                                    v-for="(child) in specializationChildrenSelected"
                                                    v-show="child.parent_id === parent.id"
                                                    :key="child.id"
                                                    class="ml-5"
                                                >
                                                    <template
                                                        v-slot:default="{ active }"
                                                        v-if="child.parent_id === parent.id"
                                                    >
                                                        <v-list-item-action
                                                            class="ma-0 pa-0"
                                                        >
                                                            <v-btn
                                                                @click="removeSpecialization(child)"
                                                                small
                                                                icon
                                                            >
                                                                <v-icon small color="grey lighten-1">mdi-close</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="pl-2">
                                                                {{ child.name }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>
                                            </v-list-group>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-5 pt-0">
                                        Регион
                                    </v-col>
                                    <v-col md="8">
                                        <v-autocomplete
                                            @change="addWorld"
                                            v-model="temp.world"
                                            :items="world.data"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="errors.world"
                                            name="world"
                                            label="Регион"
                                            class="mt-0 pt-0"
                                        ></v-autocomplete>
                                        <v-list
                                            class="ml-0 mt-0 pa-0"
                                            dense
                                        >
                                            <v-list-item
                                                v-for="(world) in worldSelected"
                                                :key="world.id"
                                            >
                                                <template
                                                    v-slot:default="{ active }"
                                                >
                                                    <v-list-item-action
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-btn
                                                            @click="removeWorld(world)"
                                                            x-small
                                                            icon
                                                        >
                                                            <v-icon small color="grey lighten-1">mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content class="pa-0">
                                                        <v-list-item-title class="pl-2">
                                                            {{ world.name }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                        <v-select
                                            v-model="data.relocation"
                                            :error-messages="errors.relocation"
                                            :items="relocation.data"
                                            :cache-items="true"
                                            item-text="name"
                                            item-value="id"
                                            class="mt-2 pt-0"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-1" v-if="metroByCity.length">
                                    <v-col md="4" class="mt-2 pt-0">
                                        Метро
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-autocomplete
                                            @change="addMetro"
                                            v-model="temp.metro"
                                            :items="metroByCity"
                                            item-text="name"
                                            item-value="hh_id"
                                            :error-messages="errors.world"
                                            name="metro"
                                            label="Метро"
                                            class="mt-0 pt-0"
                                        >
                                            <template slot="item" slot-scope="data">
                                                <v-icon
                                                    :color="'#' + data.item.color"
                                                >
                                                    mdi-circle-medium
                                                </v-icon>
                                                {{ data.item.name }}
                                            </template>
                                        </v-autocomplete>
                                        <v-list
                                            class="ml-0 mt-0 pa-0"
                                            dense
                                        >
                                            <v-list-item
                                                v-for="(metro) in metroSelected"
                                                :key="metro.hh_id"
                                            >
                                                <template
                                                    v-slot:default="{ active }"
                                                >
                                                    <v-list-item-action
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-btn
                                                            @click="removeMetro(metro)"
                                                            x-small
                                                            icon
                                                        >
                                                            <v-icon small color="grey lighten-1">mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content class="pa-0">
                                                        <v-list-item-title class="pl-2">
                                                            {{ metro.name }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-5 pt-0">
                                        Зарплата
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        от
                                        <v-text-field
                                            v-model="data.salary_from"
                                            :error-messages="errors.salary_from"
                                            name="salary_from"
                                            class="d-inline-block mx-2"
                                            type="text">
                                        </v-text-field>
                                        до
                                        <v-text-field
                                            v-model="data.salary_to"
                                            :error-messages="errors.salary_to"
                                            name="salary_to"
                                            class="d-inline-block mx-2"
                                            type="text">
                                        </v-text-field>
                                        <v-select
                                            v-model="data.currency"
                                            :error-messages="errors.currency"
                                            :items="currency.data"
                                            :cache-items="true"
                                            item-text="name"
                                            item-value="code"
                                            label="Валюта"
                                            class="d-inline-block ml-2"
                                        ></v-select>
                                        <v-checkbox
                                            v-model="data.only_with_salary"
                                            label="Не показывать резюме без зарплаты"
                                            class="ma-0 pa-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="pb-5">
                                    <v-col md="4" class="mt-0 pt-0">
                                        Статус поиска работы
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                       <v-checkbox
                                            v-model="data.job_search_status"
                                            label="Активно ищу работу"
                                            value="active_search"
                                            hide-details
                                            class="my-0 py-0"
                                        />
                                        <v-checkbox
                                            v-model="data.job_search_status"
                                            label="Рассматриваю входящие предложения"
                                            value="looking_for_offers"
                                            hide-details
                                            class="my-0 py-0"
                                        />
                                        <v-checkbox
                                            v-model="data.job_search_status"
                                            label="Не ищу работу"
                                            value="not_looking_for_job"
                                            hide-details
                                            class="my-0 py-0"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-0 pt-0">
                                        Опыт работы
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                       <v-checkbox
                                            v-model="data.experience"
                                            label="Нет опыта"
                                            value="noExperience"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.experience"
                                            label="От 1 года до 3 лет"
                                            value="between1And3"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.experience"
                                            label="От 3 до 6 лет"
                                            value="between3And6"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.experience"
                                            label="Более 6 лет"
                                            value="moreThan6"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-5 pt-0">
                                        Ключевые навыки
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-combobox
                                            v-model="data.skill"
                                            :loading="skills.loading"
                                            :items="skills.items"
                                            :search-input.sync="skills.search"
                                            :error-messages="errors.skill"
                                            item-value="id"
                                            item-text="text"
                                            multiple
                                            chips
                                            cache-items
                                            hide-no-data
                                            hide-details
                                            class="mb-5"
                                        >
                                            <template v-slot:selection="{ attrs, item, select, selected }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    :input-value="selected"
                                                    close
                                                    @click="select"
                                                    @click:close="removeSkill(item)"
                                                >
                                                    {{ item.text }}
                                                </v-chip>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-1">
                                    <v-col md="4" class="mt-2 pt-0">
                                        Образование
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-select
                                            v-model="data.education"
                                            :error-messages="errors.education"
                                            :items="education.data"
                                            :cache-items="true"
                                            item-text="name"
                                            item-value="id"
                                            class="d-inline-block mt-0 pt-0"
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-1">
                                    <v-col md="4" class="mt-2 pt-0">
                                        Гражданство
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-autocomplete
                                            @change="addCitizenship"
                                            v-model="temp.citizenship"
                                            :items="country.data"
                                            item-text="name"
                                            item-value="hh_id"
                                            :error-messages="errors.citizenship"
                                            name="citizenship"
                                            label="Гражданство"
                                            class="mt-0 pt-0"
                                        >
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }}
                                            </template>
                                        </v-autocomplete>
                                        <v-list
                                            class="ml-0 mt-0 pa-0"
                                            dense
                                        >
                                            <v-list-item
                                                v-for="(country) in citizenshipSelected"
                                                :key="country.hh_id"
                                            >
                                                <template
                                                    v-slot:default="{ active }"
                                                >
                                                    <v-list-item-action
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-btn
                                                            @click="removeCitizenship(country)"
                                                            x-small
                                                            icon
                                                        >
                                                            <v-icon small color="grey lighten-1">mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content class="pa-0">
                                                        <v-list-item-title class="pl-2">
                                                            {{ country.name }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-1">
                                    <v-col md="4" class="mt-2 pt-0">
                                        Разрешение на работу
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-autocomplete
                                            @change="addWorkTicket"
                                            v-model="temp.work_ticket"
                                            :items="country.data"
                                            item-text="name"
                                            item-value="hh_id"
                                            :error-messages="errors.work_ticket"
                                            name="work_ticket"
                                            label="Разрешение на работу"
                                            class="mt-0 pt-0"
                                        >
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }}
                                            </template>
                                        </v-autocomplete>
                                        <v-list
                                            class="ml-0 mt-0 pa-0"
                                            dense
                                        >
                                            <v-list-item
                                                v-for="(country) in workTicketSelected"
                                                :key="country.hh_id"
                                            >
                                                <template
                                                    v-slot:default="{ active }"
                                                >
                                                    <v-list-item-action
                                                        class="ma-0 pa-0"
                                                    >
                                                        <v-btn
                                                            @click="removeWorkTicket(country)"
                                                            x-small
                                                            icon
                                                        >
                                                            <v-icon small color="grey lighten-1">mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content class="pa-0">
                                                        <v-list-item-title class="pl-2">
                                                            {{ country.name }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-5 pt-0">
                                        Возраст
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        от
                                        <v-text-field
                                            v-model="data.age_from"
                                            :error-messages="errors.age_from"
                                            name="age_from"
                                            class="d-inline-block mx-2"
                                            type="text">
                                        </v-text-field>
                                        до
                                        <v-text-field
                                            v-model="data.age_to"
                                            :error-messages="errors.age_to"
                                            name="age_to"
                                            class="d-inline-block mx-2"
                                            type="text">
                                        </v-text-field>
                                        <v-checkbox
                                            v-model="data.only_with_age"
                                            label="Не показывать резюме без указания возраста"
                                            class="ma-0 pa-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-3 pt-0">
                                        Фотография
                                    </v-col>
                                    <v-col md="8">
                                        <v-checkbox
                                            v-model="data.only_with_photo"
                                            label="Только с фотографией"
                                            class="mt-0 pt-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-1 pt-0">
                                        Пол
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-radio-group
                                            v-model="data.gender"
                                            class="mt-0 pt-0"
                                        >
                                            <v-radio
                                                label="Не имеет значения"
                                                value="unknown"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="Мужской"
                                                value="male"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="Женский"
                                                value="female"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-0 pt-0">
                                        Тип занятости
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-checkbox
                                            v-model="data.employment"
                                            label="Полная занятость"
                                            value="full"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.employment"
                                            label="Частичная занятость"
                                            value="part"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.employment"
                                            label="Проектная работа / Разовое задание"
                                            value="project"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.employment"
                                            label="Волонтерство"
                                            value="volunteer"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.employment"
                                            label="Стажировка"
                                            value="probation"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-0 pt-0">
                                        График работы
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-checkbox
                                            v-model="data.schedule"
                                            label="Полный день"
                                            value="fullDay"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.schedule"
                                            label="Сменный график"
                                            value="shift"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.schedule"
                                            label="Гибкий график"
                                            value="flexible"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.schedule"
                                            label="Удаленная работа"
                                            value="remote"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="data.schedule"
                                            label="Вахтовый метод"
                                            value="flyInFlyOut"
                                            hide-details
                                            class="my-0 py-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-3 pt-0">
                                        Автомобиль
                                    </v-col>
                                    <v-col md="8">
                                        <v-checkbox
                                            v-model="data.only_with_vehicle"
                                            label="Есть личный автомобиль"
                                            class="mt-0 pt-0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="mb-5">
                                    <v-col md="4" class="mt-3 pt-2">
                                        Категория прав
                                    </v-col>
                                    <v-col md="8">
                                        <v-btn-toggle
                                            v-model="data.driver_license_type"
                                            dense borderless
                                            multiple
                                        >
                                            <v-btn value="A">A</v-btn>
                                            <v-btn value="B">B</v-btn>
                                            <v-btn value="C">C</v-btn>
                                            <v-btn value="D">D</v-btn>
                                            <v-btn value="E">E</v-btn>
                                            <v-btn value="BE">BE</v-btn>
                                            <v-btn value="CE">CE</v-btn>
                                            <v-btn value="DE">DE</v-btn>
                                            <v-btn value="TM">TM</v-btn>
                                            <v-btn value="TB">TB</v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-2 pt-0">
                                        Знание языков
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-select
                                            v-model="data.language"
                                            :error-messages="errors.language"
                                            :items="language.language"
                                            :cache-items="true"
                                            item-text="name"
                                            item-value="id"
                                            class="d-inline-block mt-0 pt-0 mr-3"
                                        />
                                        <v-select
                                            v-model="data.language_level"
                                            :error-messages="errors.language_level"
                                            :items="language.level"
                                            :cache-items="true"
                                            item-text="name"
                                            item-value="id"
                                            class="d-inline-block mt-0 pt-0"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-1 pt-0">
                                        Сортировка
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-radio-group
                                            v-model="data.sort"
                                            class="mt-0 pt-0"
                                        >
                                            <v-radio
                                                label="По дате изменения"
                                                value="publication_time"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="По убыванию зарплат"
                                                value="salary_desc"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="По возрастанию зарплаты"
                                                value="salary_asc"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="По соответствию"
                                                value="relevance"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-1 pt-0">
                                        Выводить
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-radio-group
                                            v-model="data.period"
                                            class="mt-0 pt-0"
                                        >
                                            <v-radio
                                                label="За все время"
                                                value="0"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="За сутки"
                                                value="1"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="За последние три дня"
                                                value="3"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="За неделю"
                                                value="7"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="За месяц"
                                                value="30"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="За год"
                                                value="365"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" class="mt-1 pt-0">
                                        Показывать на странице
                                    </v-col>
                                    <v-col md="8" class="mt-0 pt-0">
                                        <v-radio-group
                                            v-model="data.per_page"
                                            class="mt-0 pt-0"
                                        >
                                            <v-radio
                                                label="20"
                                                value="20"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                            <v-radio
                                                label="50"
                                                value="50"
                                                class="mt-0 pt-0"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="progress"
                                    type="submit"
                                    color="primary"
                                >
                                    Найти
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="resume.length > 0" class="mx-0">
            <v-spacer />
            <v-btn @click="options.show = true" icon><v-icon>mdi-cog</v-icon></v-btn>
        </v-row>
        <v-row
            v-for="(r) in resume"
            :key="r.id"
        >
            <v-col
                v-if="$moment(user.accessTill).diff($moment(), 'minutes') > 0 && (user.resumeBasicLimit > 0 || user.resumeExtraLimit > 0)"
                xs="12"
            >
                <v-card min-height="204px" :disabled="user.resumeHideViewed && r.history">
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>
                            <v-icon
                                v-if="r.history"
                                class="mr-1"
                                title="Просмотрено"
                                style="margin-top: -3px"
                            >
                                mdi-eye
                            </v-icon>
                            <a
                                :href="'/console/resume/' + r.id"
                                class="text-decoration-none"
                                target="_blank"
                            >
                                {{ r.title }}
                            </a>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <img v-if="options.data.image && r.photo" :src="r.photo.small" align="right" width="60">
                        <v-chip
                            v-if="!!r.job_search_status"
                            class="mb-2"
                        >
                            {{ r.job_search_status.name }}
                        </v-chip>
                        <p v-if="r.age">
                            Возраст, лет: {{ r.age }}
                        </p>
                        <p v-if="r.salary">
                            Оплата: {{ r.salary.amount }}
                            <span v-if="r.salary.currency === 'RUR'">
                                руб.
                            </span>
                            <span v-if="r.salary.currency === 'EUR'">
                                EUR
                            </span>
                            <span v-if="r.salary.currency === 'USD'">
                                USD
                            </span>
                        </p>
                        <p v-if="options.data.experience_total && r.total_experience">
                            Опыт работы:
                            <span v-if="r.total_experience.months >= 12">
                                {{ (r.total_experience.months / 12).toFixed(0) }} лет
                            </span>
                            <span v-if="r.total_experience.months % 12 > 0">
                                {{ r.total_experience.months % 12 }} месяцев
                            </span>
                        </p>
                        <p v-if="options.data.area && r.area">
                            Регион:
                            <span>
                                {{ r.area.name }}
                            </span>
                        </p>
                        <div v-if="options.data.education_primary && r.education.primary && r.education.primary.length > 0" class="my-5">
                            Основное образование:
                            <br>
                            <div
                                v-for="(e, i) in r.education.primary"
                                :key="e.name + e.year + i"
                            >
                                {{ e.name }} ({{ e.year }})
                            </div>
                        </div>
                        <p v-if="options.data.experience_last && r.experience && r.experience.length > 0">
                            Последнее место работы: {{ r.experience[0].position }}
                            <br>
                            {{ r.experience[0].company }}, {{ $moment(r.experience[0].start).format('DD.MM.YYYY') }} - <span v-if="r.experience[0].end">{{ $moment(r.experience[0].end).format('DD.MM.YYYY') }}</span><span v-else>по настоящее время</span>
                        </p>
                        <div v-if="options.data.experience && r.experience && r.experience.length > 0">
                            Компании, в которых работал кандидат:
                            <br>
                            <div
                                v-for="(e, i) in r.experience"
                                :key="e.company + e.start + i"
                            >
                                {{ e.company }}, {{ $moment(e.start).format('DD.MM.YYYY') }} - <span v-if="e.end">{{ $moment(e.end).format('DD.MM.YYYY') }}</span><span v-else>по настоящее время</span>
                            </div>
                        </div>
                        <p v-if="options.data.updated_at" class="grey--text">
                            <span v-if="r.updated_at">
                                Обновлено
                                <span v-if="$moment(r.updated_at).add(1, 'years').isBefore($moment())">
                                    {{ $moment(r.updated_at).format('D MMMM YYYY HH:mm') }}
                                </span>
                                <span v-else>
                                    {{ $moment(r.updated_at).format('D MMMM HH:mm') }}
                                </span>
                            </span>
                            <span v-else>
                                Создано {{ $moment(r.created_at).format('D MMMM HH:mm') }}
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-pagination
            v-if="pages > 1"
            v-model="data.page"
            :length="pages"
            :disabled="progress"
            class="my-4"
        ></v-pagination>
        <v-dialog
            v-if="specialization.show"
            v-model="specialization.show"
            max-width="500"
        >
            <v-card>
                <v-form
                    @submit.prevent="applySpecialization"
                    autocomplete="off"
                >
                    <v-card-text>
                        <v-list>
                            <v-list-group
                                v-for="(parent) in specializationParents"
                                :key="parent.id"
                                group="1"
                            >
                                <template v-slot:activator>
                                    <v-list-item-action
                                        class="ml-0 mr-2"
                                    >
                                        <v-checkbox
                                            @click.stop="changeSpecialization(parent)"
                                            v-model="specialization.selected"
                                            :value="parent.id"
                                            color="primary"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ parent.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item
                                    v-for="(child) in specialization.data"
                                    v-show="child.parent_id === parent.id"
                                    :key="child.id"
                                >
                                    <template
                                        v-slot:default="{ active }"
                                        v-if="child.parent_id === parent.id"
                                    >
                                        <v-list-item-action
                                            class="ml-5 mr-2"
                                        >
                                            <v-checkbox
                                                @click.stop="changeSpecialization(child)"
                                                v-model="specialization.selected"
                                                :value="child.id"
                                                color="primary"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ child.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-group>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="hideSpecializationPopup"
                            :disabled="specialization.progress"
                            text
                            color="error"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="specialization.progress"
                            type="submit"
                            color="primary"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="options.show"
            v-model="options.show"
            max-width="600"
        >
            <v-card>
                <v-card-title class="headline">Настройки</v-card-title>
                <v-form
                    @submit.prevent="updateSearchOptions"
                    autocomplete="off"
                >
                    <v-card-text>
                        <v-container>
                            <v-checkbox
                                v-model="options.data.updated_at"
                                label="Время изменения"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.image"
                                label="Фото"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.experience_last"
                                label="Последнее место работы"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.area"
                                label="Регион"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.education_primary"
                                label="Основное образование"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.experience"
                                label="Названия компаний, в которых работали"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.experience_total"
                                label="Опыт работы"
                                class="ma-0"
                            />
                            <v-checkbox
                                v-model="options.data.hide"
                                label="Скрывать просмотренные резюме"
                                class="ma-0"
                            />
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            @click="options.show = false"
                            :disabled="options.progress"
                            text
                            color="error"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="options.progress"
                            type="submit"
                            color="primary"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "SearchController",
    data () {
        return {
            autocomplete1: {
                items: [],
                loading: false,
            },
            autocomplete2: {
                items: [],
                loading: false,
            },
            autocomplete3: {
                items: [],
                loading: false,
            },
            country: {
                data: [],
            },
            currency: {
                data: [
                    {
                        code: 'RUR',
                        name: 'Руб.',
                    },
                    {
                        code: 'EUR',
                        name: 'EUR',
                    },
                    {
                        code: 'USD',
                        name: 'USD',
                    },
                ],
            },
            data: {
                age_from: '',
                age_to: '',
                citizenship: [],
                currency: 'RUR',
                driver_license_type: [],
                education: '',
                employment: [],
                experience: [],
                gender: 'unknown',
                job_search_status: [],
                language: '',
                language_level: '',
                metro: [],
                only_with_age: false,
                only_with_photo: false,
                only_with_salary: false,
                only_with_vehicle: false,
                page: 1,
                per_page: '50',
                period: '0',
                query1: '',
                query1_fields: ['everywhere'],
                query1_logic: 'all',
                query2: '',
                query2_fields: ['everywhere'],
                query2_logic: 'all',
                query3: '',
                query3_fields: ['everywhere'],
                query3_logic: 'all',
                relocation: 'living_or_relocation',
                salary_from: '',
                salary_to: '',
                schedule: [],
                skill: [],
                sort: 'relevance',
                specialization: [],
                work_ticket: [],
                world: [],
            },
            education: {
                data: [
                    {
                        id: 'secondary',
                        name: 'Среднее',
                    },
                    {
                        id: 'special_secondary',
                        name: 'Среднее специальное',
                    },
                    {
                        id: 'unfinished_higher',
                        name: 'Неоконченное высшее',
                    },
                    {
                        id: 'higher',
                        name: 'Высшее',
                    },
                    {
                        id: 'bachelor',
                        name: 'Бакалавр',
                    },
                    {
                        id: 'master',
                        name: 'Магистр',
                    },
                    {
                        id: 'candidate',
                        name: 'Кандидат наук',
                    },
                    {
                        id: 'doctor',
                        name: 'Доктор наук',
                    },
                ],
            },
            errors: {
                age_from: [],
                age_to: [],
                citizenship: [],
                currency: [],
                education: [],
                query1: [],
                query2: [],
                query3: [],
                salary_from: [],
                salary_to: [],
                skill: [],
                work_ticket: [],
                world: [],
            },
            hide: false,
            language: {
                language: [
                    {
                        id: "eng",
                        name: "Английский",
                    },
                    {
                        id: "deu",
                        name: "Немецкий",
                    },
                    {
                        id: "fra",
                        name: "Французский",
                    },
                    {
                        id: "abq",
                        name: "Абазинский",
                    },
                    {
                        id: "abk",
                        name: "Абхазский",
                    },
                    {
                        id: "ava",
                        name: "Аварский",
                    },
                    {
                        id: "aze",
                        name: "Азербайджанский",
                    },
                    {
                        id: "sqi",
                        name: "Албанский",
                    },
                    {
                        id: "amh",
                        name: "Амхарский",
                    },
                    {
                        id: "ara",
                        name: "Арабский",
                    },
                    {
                        id: "hye",
                        name: "Армянский",
                    },
                    {
                        id: "afr",
                        name: "Африкаанс",
                    },
                    {
                        id: "eus",
                        name: "Баскский",
                    },
                    {
                        id: "bak",
                        name: "Башкирский",
                    },
                    {
                        id: "bel",
                        name: "Белорусский",
                    },
                    {
                        id: "ben",
                        name: "Бенгальский",
                    },
                    {
                        id: "mya",
                        name: "Бирманский",
                    },
                    {
                        id: "bul",
                        name: "Болгарский",
                    },
                    {
                        id: "bos",
                        name: "Боснийский",
                    },
                    {
                        id: "bua",
                        name: "Бурятский",
                    },
                    {
                        id: "hun",
                        name: "Венгерский",
                    },
                    {
                        id: "vie",
                        name: "Вьетнамский",
                    },
                    {
                        id: "nld",
                        name: "Голландский",
                    },
                    {
                        id: "ell",
                        name: "Греческий",
                    },
                    {
                        id: "kat",
                        name: "Грузинский",
                    },
                    {
                        id: "dag",
                        name: "Дагестанский",
                    },
                    {
                        id: "dar",
                        name: "Даргинский",
                    },
                    {
                        id: "dan",
                        name: "Датский",
                    },
                    {
                        id: "heb",
                        name: "Иврит",
                    },
                    {
                        id: "inh",
                        name: "Ингушский",
                    },
                    {
                        id: "ind",
                        name: "Индонезийский",
                    },
                    {
                        id: "gle",
                        name: "Ирландский",
                    },
                    {
                        id: "isl",
                        name: "Исландский",
                    },
                    {
                        id: "spa",
                        name: "Испанский",
                    },
                    {
                        id: "ita",
                        name: "Итальянский",
                    },
                    {
                        id: "kbd",
                        name: "Кабардино-черкесский",
                    },
                    {
                        id: "kaz",
                        name: "Казахский",
                    },
                    {
                        id: "krc",
                        name: "Карачаево-балкарский",
                    },
                    {
                        id: "krl",
                        name: "Карельский",
                    },
                    {
                        id: "cat",
                        name: "Каталанский",
                    },
                    {
                        id: "kas",
                        name: "Кашмирский",
                    },
                    {
                        id: "zho",
                        name: "Китайский",
                    },
                    {
                        id: "kom",
                        name: "Коми",
                    },
                    {
                        id: "kor",
                        name: "Корейский",
                    },
                    {
                        id: "crs",
                        name: "Креольский (Сейшельские острова)",
                    },
                    {
                        id: "kum",
                        name: "Кумыкский",
                    },
                    {
                        id: "kur",
                        name: "Курдский",
                    },
                    {
                        id: "khm",
                        name: "Кхмерский (Камбоджийский)",
                    },
                    {
                        id: "kir",
                        name: "Кыргызский",
                    },
                    {
                        id: "lbe",
                        name: "Лакский",
                    },
                    {
                        id: "lao",
                        name: "Лаосский",
                    },
                    {
                        id: "lat",
                        name: "Латинский",
                    },
                    {
                        id: "lav",
                        name: "Латышский",
                    },
                    {
                        id: "lez",
                        name: "Лезгинский",
                    },
                    {
                        id: "lit",
                        name: "Литовский",
                    },
                    {
                        id: "mke",
                        name: "Македонский",
                    },
                    {
                        id: "zlm",
                        name: "Малазийский",
                    },
                    {
                        id: "mns",
                        name: "Мансийский",
                    },
                    {
                        id: "chm",
                        name: "Марийский",
                    },
                    {
                        id: "mon",
                        name: "Монгольский",
                    },
                    {
                        id: "nep",
                        name: "Непальский",
                    },
                    {
                        id: "nog",
                        name: "Ногайский",
                    },
                    {
                        id: "nor",
                        name: "Норвежский",
                    },
                    {
                        id: "oss",
                        name: "Осетинский",
                    },
                    {
                        id: "pan",
                        name: "Панджаби",
                    },
                    {
                        id: "fas",
                        name: "Персидский",
                    },
                    {
                        id: "pol",
                        name: "Польский",
                    },
                    {
                        id: "por",
                        name: "Португальский",
                    },
                    {
                        id: "pus",
                        name: "Пушту",
                    },
                    {
                        id: "ron",
                        name: "Румынский",
                    },
                    {
                        id: "rus",
                        name: "Русский",
                    },
                    {
                        id: "san",
                        name: "Санскрит",
                    },
                    {
                        id: "srp",
                        name: "Сербский",
                    },
                    {
                        id: "slk",
                        name: "Словацкий",
                    },
                    {
                        id: "slv",
                        name: "Словенский",
                    },
                    {
                        id: "som",
                        name: "Сомалийский",
                    },
                    {
                        id: "swa",
                        name: "Суахили",
                    },
                    {
                        id: "tgl",
                        name: "Тагальский",
                    },
                    {
                        id: "tgk",
                        name: "Таджикский",
                    },
                    {
                        id: "tha",
                        name: "Тайский",
                    },
                    {
                        id: "tly",
                        name: "Талышский",
                    },
                    {
                        id: "tam",
                        name: "Тамильский",
                    },
                    {
                        id: "tat",
                        name: "Татарский",
                    },
                    {
                        id: "bod",
                        name: "Тибетский",
                    },
                    {
                        id: "tyv",
                        name: "Тувинский",
                    },
                    {
                        id: "tur",
                        name: "Турецкий",
                    },
                    {
                        id: "tuk",
                        name: "Туркменский",
                    },
                    {
                        id: "udm",
                        name: "Удмуртский",
                    },
                    {
                        id: "uzb",
                        name: "Узбекский",
                    },
                    {
                        id: "uig",
                        name: "Уйгурский",
                    },
                    {
                        id: "ukr",
                        name: "Украинский",
                    },
                    {
                        id: "urd",
                        name: "Урду",
                    },
                    {
                        id: "fin",
                        name: "Финский",
                    },
                    {
                        id: "vls",
                        name: "Фламандский",
                    },
                    {
                        id: "hin",
                        name: "Хинди",
                    },
                    {
                        id: "hrv",
                        name: "Хорватский",
                    },
                    {
                        id: "che",
                        name: "Чеченский",
                    },
                    {
                        id: "ces",
                        name: "Чешский",
                    },
                    {
                        id: "chv",
                        name: "Чувашский",
                    },
                    {
                        id: "swe",
                        name: "Шведский",
                    },
                    {
                        id: "epo",
                        name: "Эсперанто",
                    },
                    {
                        id: "est",
                        name: "Эстонский",
                    },
                    {
                        id: "sah",
                        name: "Якутский",
                    },
                    {
                        id: "jpn",
                        name: "Японский",
                    },
                ],
                level: [
                    {
                        id: "a1",
                        name: "A1 — Начальный"
                    },
                    {
                        id: "a2",
                        name: "A2 — Элементарный"
                    },
                    {
                        id: "b1",
                        name: "B1 — Средний"
                    },
                    {
                        id: "b2",
                        name: "B2 — Средне-продвинутый"
                    },
                    {
                        id: "c1",
                        name: "C1 — Продвинутый"
                    },
                    {
                        id: "c2",
                        name: "C2 — В совершенстве"
                    },
                    {
                        id: "l1",
                        name: "Родной"
                    },
                ],
            },
            metro: [],
            options: {
                data: {
                    area: false,
                    education_primary: false,
                    experience: false,
                    experience_last: true,
                    experience_total: true,
                    image: true,
                    updated_at: true,
                    hide: false,
                },
                progress: false,
                show: false,
            },
            query_fields: [
                {
                    id: 'everywhere',
                    name: 'Везде',
                },
                {
                    id: 'title',
                    name: 'В названии резюме',
                },
                {
                    id: 'education',
                    name: 'В образовании',
                },
                {
                    id: 'skills',
                    name: 'В ключевых навыках',
                },
                {
                    id: 'experience',
                    name: 'В опыте работы',
                    children: [
                        {
                            id: 'experience_company',
                            name: 'В компаниях и отраслях',
                        },
                        {
                            id: 'experience_position',
                            name: 'В должностях',
                        },
                        {
                            id: 'experience_description',
                            name: 'В обязанностях',
                        },
                    ],
                },
            ],
            pages: 1,
            progress: false,
            queries: 1,
            relocation: {
                data: [
                    {
                        id: 'living_or_relocation',
                        name: 'Живут в указанном регионе или готовы переехать в него',
                    },
                    {
                        id: 'living',
                        name: 'Живут в указанном регионе',
                    },
                    {
                        id: 'living_but_relocation',
                        name: 'Живут в указанном регионе и готовы к переезду куда-либо',
                    },
                    {
                        id: 'relocation',
                        name: 'Не живут в указанном регионе, но готовы переехать в него',
                    },
                ],
            },
            resume: [],
            show: false,
            skills: {
                items: [],
                loading: false,
            },
            specialization: {
                data: [],
                selected: [],
                show: false,
            },
            temp: {
                citizenship: '',
                metro: '',
                work_ticket: '',
                world: '',
            },
            wait: false,
            warning: false,
            world: {
                data: [],
                selected: [],
                show: false,
            },
        }
    },
    computed: {
        citizenshipSelected () {
            return this.country.data.filter(c => {return this.data.citizenship.includes(c.hh_id)})
        },
        metroByCity () {
            if (this.data.world.length !== 1) {
                return []
            }
            return this.metro.filter(m => {return m.city_id === this.data.world[0]})
        },
        metroSelected () {
            return this.metro.filter(m => {return this.data.metro.includes(m.hh_id)})
        },
        specializationChildren (parent_id) {
            return this.specialization.data.filter(s => {return s.id === parent_id})
        },
        specializationParents () {
            return this.specialization.data.filter(s => {return s.parent_id === null})
        },
        specializationChildrenSelected () {
            return this.specialization.data.filter(s => {return s.parent_id !== null && this.data.specialization.includes(s.id)})
        },
        specializationParentsSelected () {
            let ids = []

            this.specialization.data.forEach(s => {
                if (s.parent_id === null) {
                    if (this.data.specialization.includes(s.id)) {
                        ids.push(s.id)
                    }
                } else if (this.data.specialization.includes(s.id) && !ids.includes(s.parent_id)) {
                    ids.push(s.parent_id)
                }
            })

            return this.specialization.data.filter(s => {return ids.includes(s.id)})
        },
        user () {
            return this.$store.getters.user
        },
        workTicketSelected () {
            return this.country.data.filter(c => {return this.data.work_ticket.includes(c.hh_id)})
        },
        worldSelected () {
            return this.world.data.filter(w => {return this.data.world.includes(w.id)})
        },
    },
    watch: {
        'autocomplete1.search' (query) {
            query && query !== this.data.query1 && query.length > 1 && this.autosuggest1(query)
        },
        'autocomplete2.search' (query) {
            query && query !== this.data.query2 && query.length > 1 && this.autosuggest2(query)
        },
        'autocomplete3.search' (query) {
            query && query !== this.data.query3 && query.length > 1 && this.autosuggest3(query)
        },
        'data.page' () {
            this.searchResume()
        },
        'data.query1_fields' (newFields, oldFields) {
            if (newFields.indexOf('everywhere') !== -1 && oldFields.indexOf('everywhere') === -1 && newFields.length > 1) {
                this.data.query1_fields = ['everywhere']
            }
            if (oldFields.indexOf('everywhere') !== -1 && newFields.indexOf('everywhere') !== -1 && newFields.length > 1) {
                this.data.query1_fields = newFields.filter(f => {return f !== 'everywhere'})
            }
            if (newFields.length === 0) {
                this.data.query1_fields = ['everywhere']
            }
        },
        'data.query2_fields' (newFields, oldFields) {
            if (newFields.indexOf('everywhere') !== -1 && oldFields.indexOf('everywhere') === -1 && newFields.length > 1) {
                this.data.query2_fields = ['everywhere']
            }
            if (oldFields.indexOf('everywhere') !== -1 && newFields.indexOf('everywhere') !== -1 && newFields.length > 1) {
                this.data.query2_fields = newFields.filter(f => {return f !== 'everywhere'})
            }
            if (newFields.length === 0) {
                this.data.query2_fields = ['everywhere']
            }
        },
        'data.query3_fields' (newFields, oldFields) {
            if (newFields.indexOf('everywhere') !== -1 && oldFields.indexOf('everywhere') === -1 && newFields.length > 1) {
                this.data.query3_fields = ['everywhere']
            }
            if (oldFields.indexOf('everywhere') !== -1 && newFields.indexOf('everywhere') !== -1 && newFields.length > 1) {
                this.data.query3_fields = newFields.filter(f => {return f !== 'everywhere'})
            }
            if (newFields.length === 0) {
                this.data.query3_fields = ['everywhere']
            }
        },
        'skills.search' (query) {
            query && query !== this.data.skill && query.length > 1 && this.autosuggestSkill(query)
        },
    },
    created () {
        this.searchOptions()
        this.searchMetro()
        this.searchSpecialization()
        this.searchCountry()
        this.searchWorld()
    },
    methods: {
        autosuggest1 (term) {
            this.autocomplete1.loading = true

            this.$axios.post('/console/search/autosuggest', {term: term})
                .then(res => {
                    if (res.data.success) {
                        this.autocomplete1.items = res.data.data
                    }

                    this.autocomplete1.loading = false
                })
        },

        autosuggest2 (term) {
            this.autocomplete2.loading = true

            this.$axios.post('/console/search/autosuggest', {term: term})
                .then(res => {
                    if (res.data.success) {
                        this.autocomplete2.items = res.data.data
                    }

                    this.autocomplete2.loading = false
                })
        },

        autosuggest3 (term) {
            this.autocomplete3.loading = true

            this.$axios.post('/console/search/autosuggest', {term: term})
                .then(res => {
                    if (res.data.success) {
                        this.autocomplete3.items = res.data.data
                    }

                    this.autocomplete3.loading = false
                })
        },

        autosuggestSkill (term) {
            this.skills.loading = true

            this.$axios.post('/console/search/autosuggest/skill', {term: term})
                .then(res => {
                    if (res.data.success) {
                        this.skills.items = res.data.data
                    }

                    this.skills.loading = false
                })
        },

        searchResume () {
            this.progress = true

            this.$axios.post('/console/search/resume', this.data)
                .then(res => {
                    if (res.data.success) {
                        this.resume = res.data.data.data
                        this.hide = true
                        this.pages = res.data.data.meta.pages
                        this.warning = false
                        this.progress = false
                        window.scrollTo(0, 0);
                    } else if (res.data.errors.query[0] === 'url') {
                        this.warning = true
                        window.scrollTo(0, 0);
                    }
                })
        },

        searchCountry () {
            this.$axios.post('/console/search/country')
                .then(res => {
                    if (res.data.success) {
                        this.country.data = res.data.data
                    }
                })
        },

        searchMetro () {
            this.$axios.post('/console/search/metro')
                .then(res => {
                    if (res.data.success) {
                        this.metro = res.data.data
                    }
                })
        },

        searchOptions () {
            this.$axios.post('/console/search/options')
                .then(res => {
                    if (res.data.success) {
                        this.options.data = res.data.data
                        this.options.data.hide = this.$store.getters.user.resumeHideViewed
                        this.options.show = false
                    }
                })
        },

        searchSpecialization () {
            this.$axios.post('/console/search/specialization')
                .then(res => {
                    if (res.data.success) {
                        this.specialization.data = res.data.data
                    }
                })
        },

        searchWorld () {
            this.$axios.post('/console/search/world')
                .then(res => {
                    if (res.data.success) {
                        this.world.data = res.data.data
                    }
                })
        },

        addCitizenship (hh_id) {
            this.data.citizenship.push(hh_id)
            this.$nextTick(() => {
                this.temp.citizenship = ''
            })
        },

        addMetro (hh_id) {
            if (this.data.metro.includes(hh_id)) {
                return
            }

            const metro = this.metro.filter(m => {return m.hh_id === hh_id})[0]

            if (metro.type === 'line') {
                this.metro.forEach(item => {
                    if (item.type === 'station' && this.data.metro.includes(item.hh_id) && item.line_id === metro.id) {
                        this.data.metro.splice(this.data.metro.indexOf(item.hh_id), 1)
                    }
                })
            } else {
                const line = this.metro.filter(m => {return m.id === metro.line_id})[0]
                if (this.data.metro.includes(line.hh_id)) {
                    return
                }
            }

            this.data.metro.push(hh_id)

            this.$nextTick(() => {
                this.temp.metro = ''
            })
        },

        addWorkTicket (hh_id) {
            this.data.work_ticket.push(hh_id)
            this.$nextTick(() => {
                this.temp.work_ticket = ''
            })
        },

        addWorld (world) {
            this.data.metro = []
            this.data.world.push(world)
            this.$nextTick(() => {
                this.temp.world = ''
            })
        },

        applySpecialization () {
            this.data.specialization = [...this.specialization.selected]
            this.specialization.show = false
        },

        changeSpecialization (specialization) {
            if (this.specialization.selected.includes(specialization.id)) {
                if (specialization.parent_id === null) {
                    this.specialization.data.forEach(spec => {
                        if (spec.parent_id === specialization.id) {
                            this.specialization.selected.push(spec.id)
                        }
                    })
                } else if (!this.specialization.selected.includes(specialization.parent_id)) {
                    let all = true

                    this.specialization.data.forEach(spec => {
                        if (spec.parent_id === specialization.parent_id && !this.specialization.selected.includes(spec.id)) {
                            all = false
                        }
                    })

                    if (all) {
                        this.specialization.selected.push(specialization.parent_id)
                    }
                }
            } else {
                if (specialization.parent_id === null) {
                    this.specialization.data.forEach(spec => {
                        if (spec.parent_id === specialization.id) {
                            this.specialization.selected = this.specialization.selected.filter(s => s !== spec.id)
                        }
                    })
                } else {
                    this.specialization.selected = this.specialization.selected.filter(s => s !== specialization.parent_id)
                }
            }
        },

        hideSpecializationPopup () {
            this.specialization.show = false
        },

        removeSkill (item) {
            this.data.skill.splice(this.data.skill.indexOf(item), 1)
            this.data.skill = [...this.data.skill]
        },

        removeSpecialization (specialization) {
            this.data.specialization = this.data.specialization.filter(s => s !== specialization.id)
            if (specialization.parent_id === null) {
                this.specialization.data.forEach(spec => {
                    if (spec.parent_id === specialization.id) {
                        this.data.specialization = this.data.specialization.filter(s => s !== spec.id)
                    }
                })
            } else {
                this.data.specialization = this.data.specialization.filter(s => s !== specialization.parent_id)
            }
        },

        removeCitizenship (country) {
            this.data.citizenship = this.data.citizenship.filter(c => c !== country.hh_id)
        },

        removeMetro (metro) {
            this.data.metro = this.data.metro.filter(m => m !== metro.hh_id)
        },

        removeWorkTicket (country) {
            this.data.work_ticket = this.data.work_ticket.filter(c => c !== country.hh_id)
        },

        removeWorld (world) {
            this.data.metro = []
            this.data.world = this.data.world.filter(w => w !== world.id)
        },

        showSpecializationPopup () {
            this.specialization.selected = [...this.data.specialization]
            this.specialization.show = true
        },

        updateSearchOptions () {
            this.options.progress = true
            this.$store.commit('updateUserResumeHideViewed', this.options.data.hide)

            this.$axios.post('/console/search/options/update', this.options.data)
                .then(res => {
                    if (res.data.success) {
                        this.options.show = false
                        this.options.progress = false
                    }
                })
        },
    },
}
</script>

<style>
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 20px;
}

.v-card--disabled > *:not(.v-card__progress) {
    opacity: 0.3;
}
</style>
