<template>
    <v-app>
        <v-app-bar app flat clipped-right color="grey lighten-4">
            <v-app-bar-nav-icon v-if="isAuthenticated" @click="sidebar = !sidebar" />
            <v-spacer/>
            <v-btn
                v-if="isAuthenticated && (user.news > 0)"
                to="/news"
                class="ma-5"
                variant="text"
            >
                <v-icon small class="pr-2">mdi-email-outline</v-icon>
                <strong>У вас есть непрочитанная новость!</strong>
            </v-btn>
            <v-spacer/>
            <v-btn
                v-show="isAuthenticated"
                text
                @click="signOut()"
            >
                Выйти
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            v-if="isAuthenticated"
            v-model="sidebar"
            app
        >
            <v-card flat>
                <v-toolbar flat color="grey lighten-4">
                    <v-toolbar-title class="headline text-uppercase">
                        <span>ATS-JOB.RU</span>
                    </v-toolbar-title>
                </v-toolbar>
            </v-card>
            <v-divider/>
            <v-card flat>
                <p class="ma-5">
                    <span
                        v-if="$moment(user.accessTill).diff($moment(), 'minutes') > 0"
                    >
                        Доступ до {{ $moment(user.accessTill).format('DD.MM.YY') }}
                    </span>
                    <span
                        v-else
                        class="error--text"
                    >
                        Доступ заблокирован
                    </span>
                </p>
                <p class="ma-5">
                    Осталось просмотров: {{ user.resumeBasicLimit }}
                </p>
                <p class="ma-5">
                    Осталось контактов: {{ user.contactBasicLimit }}
                </p>
                <p v-if="isAuthenticated && user.resumeExtraLimit > 0" class="ma-5">
                    Докупленные просмотры: {{ user.resumeExtraLimit }}
                </p>
                <p v-if="isAuthenticated && user.contactExtraLimit > 0" class="ma-5">
                    Докупленные контакты: {{ user.contactExtraLimit }}
                </p>
                <p v-if="isAuthenticated" class="ma-5">
                    <a href="/console/news" class="black--text text-none news" :class="user.news > 0 ? 'font-weight-bold' : ''">Новости сайта <v-icon v-if="user.news > 0" class="blink-transition" small>mdi-email-outline</v-icon></a>
                </p>
                <hr>
                <v-list flat class="pt-0 pb-0">
                    <v-list-item class="grey lighten-4">
                        <v-list-item-content>
                            Разделы
                        </v-list-item-content>
                    </v-list-item>
                    <router-link
                        to="/"
                        active-class="grey lighten-4"
                        class="d-block"
                        exact flat
                    >
                        <v-list-item>
                            <v-list-item-action class="mr-3">
                                <v-icon>mdi-magnify</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Поиск</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link
                        v-if="user.history"
                        to="/history"
                        active-class="grey lighten-4"
                        class="d-block"
                        exact flat
                    >
                        <v-list-item>
                            <v-list-item-action class="mr-3">
                                <v-icon>mdi-bookmark-multiple</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>История</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link
                        v-for="(item, i) in sidebarItems"
                        :key="i"
                        :to="item.href"
                        active-class="grey lighten-4"
                        class="d-block"
                        exact flat
                    >
                        <v-list-item>
                            <v-list-item-action class="mr-3">
                                <v-icon>{{item.icon}}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <v-list-item class="grey lighten-4">
                        <v-list-item-content>
                            Сохраненные резюме
                        </v-list-item-content>
                    </v-list-item>
                    <router-link
                        v-for="(item, i) in folders"
                        :key="'folder-' + i"
                        :to="'/resume/folder/' + item.id"
                        active-class="grey lighten-4"
                        class="d-block"
                        exact flat
                    >
                        <v-list-item>
                            <v-list-item-action class="mr-3">
                                <v-icon>mdi-briefcase</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <a
                        @click="createFolderPopup"
                    >
                        <v-list-item>
                            <v-list-item-action class="mr-3">
                                <v-icon>mdi-plus</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Новая папка</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </a>
                    <v-list-item
                        v-if="users.length > 0"
                        class="grey lighten-4"
                    >
                        <v-list-item-content>
                            Менеджеры компании
                        </v-list-item-content>
                    </v-list-item>
                    <v-expansion-panels
                        v-if="users.length > 0"
                        v-model="user_id"
                        class="px-1 mx-0"
                        multiple
                        flat
                    >
                        <v-expansion-panel
                            v-for="u in users"
                            :key="'user-' + u.id"
                            class="mx-0"
                        >
                            <v-expansion-panel-header>{{ u.name }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <router-link
                                    :to="'/user/' + u.id + '/history'"
                                    active-class="grey lighten-4"
                                    class="d-block"
                                    exact flat
                                >
                                    <v-list-item class="px-1">
                                        <v-list-item-action class="mr-3">
                                            <v-icon>mdi-bookmark-multiple</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>История</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </router-link>
                                <router-link
                                    v-for="(f, i) in u.folders"
                                    :key="'folder-' + i"
                                    :to="'/user/' + u.id + '/resume/folder/' + f.id"
                                    active-class="grey lighten-4"
                                    class="d-block"
                                    exact flat
                                >
                                    <v-list-item class="px-1">
                                        <v-list-item-action class="mr-3">
                                            <v-icon>mdi-briefcase</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ f.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </router-link>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-list>
            </v-card>
        </v-navigation-drawer>
        <v-main>
            <v-divider/>
            <router-view/>
        </v-main>
        <v-dialog
            v-if="folder.create.show"
            v-model="folder.create.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Новая папка</v-card-title>
                <v-form
                    @submit.prevent="createFolder"
                    autocomplete="off"
                >
                    <v-card-text>
                        <v-text-field
                            v-model="folder.create.data.name"
                            :error-messages="folder.create.errors.name"
                            class="ma-0 pa-0"
                            name="name"
                            label="Название папки"
                            type="text">
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            @click="folder.create.show = false"
                            :disabled="folder.create.progress"
                            text
                            color="error"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="folder.create.progress"
                            type="submit"
                            color="primary"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data () {
        return {
            folder: {
                create: {
                    data: {
                        name: '',
                    },
                    errors: {
                        name: [],
                    },
                    progress: false,
                    show: false,
                },
            },
            ping: null,
            sidebar: true,
            sidebarItems: [
                {
                    href: '/vacancy',
                    title: 'Вакансии',
                    icon: 'mdi-account-group-outline',
                },
                {
                    href: '/response',
                    title: 'Отклики',
                    icon: 'mdi-message-reply',
                },
            ],
            user_id: [],
            users: [],
        }
    },
    created () {
        if (this.isAuthenticated) {
            this.ping = window.setInterval(() => {
                this.$axios.post('/console/ping')
            }, 10000)
        }

        this.searchUsers()

        if (this.$route.params.user_id) {
            this.user_id = ['user-' + this.$route.params.user_id]
        }
    },
    computed: {
        folders () {
            return JSON.parse(this.$store.getters.user.folders)
        },
        isAuthenticated () {
            return this.$store.getters.isAuthenticated
        },
        user () {
            return this.$store.getters.user
        },
    },
    watch: {
        isAuthenticated () {
            if (this.isAuthenticated) {
                this.ping = window.setInterval(() => {
                    this.$axios.post('/console/ping')
                }, 1000)
            } else if (this.ping) {
                window.clearInterval(this.ping)
            }
        },
        '$route.params.user_id' () {
            if (this.$route.params.user_id) {
                this.user_id = ['user-' + this.$route.params.user_id]
            } else {
                this.user_id = []
            }
        },
    },
    methods: {
        createFolder () {
            this.folder.create.progress = true

            this.$axios.post('/console/resume/folder/create', this.folder.create.data)
                .then(res => {
                    if (res.data.success) {
                        this.folder.create.errors.name = []
                        this.folder.create.data.name = ''
                        this.folder.create.show = false
                    } else {
                        this.folder.create.errors.name = res.data.errors.name || []
                    }

                    this.folder.create.progress = false
                })
        },

        createFolderPopup () {
            this.folder.create.show = true
        },

        searchUsers () {
            this.$axios.post('/console/user/search')
                .then(res => {
                    if (res.data.success) {
                        this.users = res.data.data
                    }
                })
        },

        signOut () {
            if (this.ping) {
                window.clearInterval(this.ping)
            }

            this.$axios.post('/console/sign-out')
                .then(res => {
                    if (res.data.success) {
                        this.$store.commit('removeToken')
                        this.$store.commit('removeUser')
                        delete this.$axios.defaults.headers.common['Authorization']
                        this.$router.push('/sign-in')
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss">
.v-btn--active {
    opacity: 1 !important;
}

.v-navigation-drawer a {
    text-decoration: none;
    color: inherit;
}

.v-navigation-drawer a:hover {
    background-color: #f5f5f5;
}

.menuable__content__active {
    .v-list-item {
        background-color: #ffffff;
    }
}

@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.blink-transition {
    animation: blink 1s infinite;
}

.news:hover {
    background-color: white !important;
}
</style>
