import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
const moment = require('moment')
import 'moment/locale/ru'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/axios'

Vue.use(VueI18n)
Vue.use(Vue2Filters)
Vue.use(VueMoment, {moment})

Vue.config.productionTip = false

const i18n = new VueI18n({
    pluralizationRules: {
        ru (choice, choicesLength) {
            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    },
    locale: 'ru',
    messages: {
        ru: {
            daysDiff: '0 дней | {n} день | {n} дня | {n} дней',
            monthsDiff: '0 месяцев | {n} месяц | {n} месяца | {n} месяцев',
            yearsDiff: '0 лет | {n} год | {n} года | {n} лет',
        },
    },
})

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),

    mounted() {
        this.$moment.locale('ru')
    },

    created () {
        this.$axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

        const authToken = localStorage.getItem('auth-token')

        if (authToken) {
            this.$axios.defaults.headers.common['Authorization'] = authToken
        }

        const self = this

        this.$axios.defaults.withCredentials = true

        this.$axios.interceptors.response.use(
            function (res) {
                if (res.headers['x-user-access-schedule'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserAccessSchedule', res.headers['x-user-access-schedule'])
                }

                if (res.headers['x-user-access-till'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserAccessTill', res.headers['x-user-access-till'])
                }

                if (res.headers['x-user-contact-basic-limit'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserContactBasicLimit', res.headers['x-user-contact-basic-limit'])
                }

                if (res.headers['x-user-contact-extra-limit'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserContactExtraLimit', res.headers['x-user-contact-extra-limit'])
                }

                if (res.headers['x-user-resume-basic-limit'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserResumeBasicLimit', res.headers['x-user-resume-basic-limit'])
                }

                if (res.headers['x-user-resume-extra-limit'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserResumeExtraLimit', res.headers['x-user-resume-extra-limit'])
                }

                if (res.headers['x-user-folders'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserFolders', res.headers['x-user-folders'])
                }

                if (res.headers['x-user-history'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserHistory', res.headers['x-user-history'])
                }

                if (res.headers['x-user-news'] && self.$store.getters.isAuthenticated) {
                    self.$store.commit('updateUserNews', res.headers['x-user-news'])
                }

                return Promise.resolve(res)
            },
            function (error) {
                if (error.response.status === 401 || error.response.status === 403) {
                    self.$store.commit('removeToken')
                    self.$store.commit('removeUser')
                    self.$router.push('/sign-in')
                } else {
                    throw error
                }
            }
        )
    }
}).$mount('#app')
