<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>История</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col
                                md="6"
                                class="mb-0"
                            >
                                <v-form @submit.prevent="searchResume">
                                    <v-layout
                                        row
                                        wrap
                                        ml-0 mb-0
                                    >
                                        <label class="pt-5 mr-5" style="min-width: 128px">Дата просмотра</label>
                                        <v-flex
                                            xs12 sm6 md4
                                            class="mr-5"
                                        >
                                            <v-menu
                                                ref="menuFrom"
                                                v-model="resume.search.menu.from"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="fromFormatted"
                                                        :error-messages="resume.search.errors.from"
                                                        label="Начало периода"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker
                                                    v-model="resume.search.filter.from"
                                                    locale="ru"
                                                    first-day-of-week="1"
                                                    color="primary"
                                                    @input="resume.search.filter.page = 1; resume.search.errors.from = []; resume.search.menu.from = false; searchResume()"
                                                />
                                            </v-menu>
                                        </v-flex>
                                        <v-flex
                                            xs12 sm6 md4
                                            class="mr-3"
                                        >
                                            <v-menu
                                                ref="menuTill"
                                                v-model="resume.search.menu.till"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="tillFormatted"
                                                        :error-messages="resume.search.errors.till"
                                                        label="Конец периода"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker
                                                    v-model="resume.search.filter.till"
                                                    locale="ru"
                                                    first-day-of-week="1"
                                                    color="primary"
                                                    @input="resume.search.filter.page = 1; resume.search.errors.till = []; resume.search.menu.till = false; searchResume()"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col md="12">
                <v-pagination
                    v-if="resume.search.total > 10"
                    v-model="resume.search.filter.page"
                    @input="searchResume"
                    :length="Math.ceil(resume.search.total / 10)"
                    class="ma-0"
                ></v-pagination>
            </v-col>
        </v-row>
        <v-row
            v-if="resume.selected.length > 0"
        >
            <v-col md="12" class="pt-1">
                <v-btn
                    @click="removeResume"
                    class="error"
                >
                    Удалить выбранное
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            v-for="r in resume.search.data"
            :key="r.id"
        >
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>
                            <v-layout
                                row
                                wrap
                                ml-0 mb-0
                            >
                                <v-flex>
                                    <v-checkbox
                                        v-model="resume.selected"
                                        :value="r.id"
                                        class="mt-4 mx-2"
                                    />
                                </v-flex>
                                <v-flex
                                    class="mt-4 mr-5"
                                >
                                    <a
                                        :href="'/console/resume/' + r.data.id"
                                        class="text-decoration-none"
                                    >
                                        {{ r.data.title }}
                                    </a>
                                </v-flex>
                            </v-layout>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        {{ r.updated }}
                    </v-toolbar>
                    <v-card-text>
                        <img v-if="r.data.photo" :src="r.data.photo.small" align="right" width="60">
                        <p v-if="r.data.age">
                            Возраст, лет: {{ r.data.age }}
                        </p>
                        <p v-if="r.data.salary">
                            Оплата: {{ r.data.salary.amount }}
                            <span v-if="r.data.salary.currency === 'RUR'">
                                руб.
                            </span>
                            <span v-if="r.data.salary.currency === 'EUR'">
                                EUR
                            </span>
                            <span v-if="r.data.salary.currency === 'USD'">
                                USD
                            </span>
                        </p>
                        <p v-if="r.data.total_experience">
                            Опыт работы:
                            <span v-if="r.data.total_experience.months >= 12">
                                {{ (r.data.total_experience.months / 12).toFixed(0) }} лет
                            </span>
                            <span v-if="r.data.total_experience.months % 12 > 0">
                                {{ r.data.total_experience.months % 12}} месяцев
                            </span>
                        </p>
                        <p v-if="r.data.experience && r.data.experience.length > 0">
                            Последнее место работы: {{ r.data.experience[0].position }}
                            <br>
                            {{ r.data.experience[0].company }}, {{ $moment(r.data.experience[0].start).format('DD.MM.YYYY') }} - <span v-if="r.data.experience[0].end">{{ $moment(r.data.experience[0].end).format('DD.MM.YYYY') }}</span><span v-else>по настоящее время</span>
                        </p>
                        <p class="grey--text">
                            <span v-if="r.data.updated_at">
                                Обновлено {{ $moment(r.data.updated_at).format('D MMMM HH:mm') }}
                            </span>
                            <span v-else>
                                Создано {{ $moment(r.data.created_at).format('D MMMM HH:mm') }}
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12">
                <v-pagination
                    v-if="resume.search.total > 10"
                    v-model="resume.search.filter.page"
                    @input="searchResume"
                    :length="Math.ceil(resume.search.total / 10)"
                    class="ma-0"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "HistoryController",
    data () {
        return {
            resume: {
                search: {
                    data: [],
                    errors: {
                        from: [],
                        till: [],
                    },
                    filter: {
                        from: '',
                        till: '',
                        page: 1,
                    },
                    menu: {
                        from: false,
                        till: false,
                    },
                    progress: false,
                    show: false,
                    total : 0,
                    wait: false,
                },
                selected: [],
            },
        }
    },
    computed: {
        fromFormatted () {
            return this.formatDate(this.resume.search.filter.from)
        },
        tillFormatted () {
            return this.formatDate(this.resume.search.filter.till)
        },
    },
    created () {
        this.searchResume()
    },
    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        removeResume () {
            if (!confirm('Удалить выбранные резюме?')) {
                return
            }

            this.$axios.post('/console/resume/history/remove', {id: this.resume.selected})
                .then(res => {
                    if (res.data.success) {
                        this.resume.selected = []
                        this.searchResume()
                    }
                })
        },

        searchResume () {
            this.$axios.post('/console/resume/history', this.resume.search.filter)
                .then(res => {
                    if (res.data.success) {
                        this.resume.search.data = res.data.data.data
                        this.resume.search.total = res.data.data.meta.total
                    }
                })
        },
    },
}
</script>

<style>
.v-input--checkbox {
    padding-top: 4px !important;
}
</style>